import React from "react";
import { useHistory } from "react-router-dom";
import { SurveyCardComponent, Hr, AnalyticButton, Active } from "./Style";
import { FiTrash2 } from "react-icons/fi";
import { Button, Row, Col, Space, Typography, Tooltip } from "antd";

const SurveyCard = (props) => {
  const history = useHistory();

  return (
    <SurveyCardComponent>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          padding: "5px 15px",
        }}
      >
        {!props.isArchive && (
          <p style={{ marginBottom: 0 }}>{props.questionNumber} Questions</p>
        )}
        {!props.isArchive && !props.isTemplate && (
          <Active active={props.active}>
            <p>{props.active ? "active" : "not active"}</p>
          </Active>
        )}
      </div>

      {props.isArchive && !props.isTemplate && (
        <Row justify="end">
          <Col>
            <Button
              onClick={props.onDelete}
              style={{ marginLeft: "auto", border: "none" }}
              shape="circle"
            >
              <FiTrash2 />
            </Button>
          </Col>
        </Row>
      )}

      <Hr />
      <div style={{ padding: "10px", textAlign: "center" }}>
        <Tooltip title={props.questionTitle}>
          <Typography.Title level={4} ellipsis={true}>
            {props.questionTitle}
          </Typography.Title>
        </Tooltip>
        {!props.isArchive && !props.isTemplate && (
          <Typography.Title level={5}>
            {props.respondents} Respondents
          </Typography.Title>
        )}
        <AnalyticButton
          onClick={
            props.isArchive || props.isTemplate
              ? props.onClick
              : () => {
                  history.push(`/dashboard/analysis/${props.id}`);
                }
          }
        >
          {props.isArchive
            ? "View Survey"
            : props.isTemplate
            ? "Select Template"
            : "See Analytic"}
        </AnalyticButton>
      </div>
    </SurveyCardComponent>
  );
};

export default SurveyCard;
