import { createSlice } from "@reduxjs/toolkit";
import { SideEffect } from "../../Services";



const archiveSlice = createSlice({
    name: 'archiveSlice',
    initialState: {
        achiveSurveys: [],
        paginationProps: {pages: 0, total: 0},
    },
    reducers: {
        addArchiveSurveys: (state, {payload}) => {
            state.achiveSurveys = payload;
        },
        addPaginationProps: (state, {payload}) => {
            state.paginationProps = payload;
        }
    }
})

export const { addArchiveSurveys, addPaginationProps } = archiveSlice.actions;

export const fetchAchiveSurvey = payload => dispatcher => {
    return SideEffect.fetchAchiveSurvey(payload).then(
         response => {
            dispatcher(addArchiveSurveys(response.data.data.surveys));
            dispatcher(addPaginationProps({pages:response.data.data.pages, total: response.data.data.total_survey }))
            return Promise.resolve(response.data.data);
         },

         err => {
            if(err.response){
                return Promise.reject(err.response.data);
            }
         }
    )
}


export const selectArchive = state => state.archiveSurveyReducer;

export default archiveSlice.reducer