import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { SideEffect } from "../../Services";

const signUpSlice = createSlice({
  name: "signUp",
  initialState: {
    data: {},
  },
  reducers: {
    updateUserInfo: (state, { payload }) => {
      state.data = payload;
    },
  },
});

export const signUpSponsor = (payload) => (dispatcher) => {
  return SideEffect.registerSponsor(payload)
    .then((response) => {
      //   console.log("the response from slice", response.data);
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      if (error.response) {
        return Promise.reject(error.response.data);
      }
    });
};

export const resendVerification = (payload) => (dispatcher) => {
  return SideEffect.resendVerificationLink(payload)
    .then((response) => {
      console.log("verification link");
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      if (error.response) {
        return Promise.reject(error.response.data);
      }
    });
};

export const { updateUserInfo } = signUpSlice.actions;

export default signUpSlice.reducers;
