import React from "react";
import { MailOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import {
  StyledInput,
  Title,
  RegisterContainer,
  Form,
  HeroContainer,
  Logo,
  StyledButtonDiv,
  RegisterButton2,
} from "../../../Register/styles";


export const EditTemplate = ({
  isModalVisible,
  showModal,
  handleCancel,
  handleOk,
  isOpen,
  onInputChange,
  onButtonClick,
  onFinish,
}) => {
  /* const onFinish = (values) => {
    console.log("Success:", values);
  }; */

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Modal
      visible={isOpen}
      title={false}
      footer={false}
      okButtonProps={{ style: { display: "none" } }}
      onCancel={handleCancel}
      style={{ alignItems: "center" }}
    >
      <HeroContainer>
        <RegisterContainer>
          <Title admin={true}>Edit Template Name</Title>

          <Form
            layout="vertical"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Template Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input template name!",
                },            
              ]}
            >
              <StyledInput
                onChange={onInputChange}
                prefix={<MailOutlined className="site-form-item-icon" />}
              />
            </Form.Item>
  
              <RegisterButton2
                onClick={onButtonClick}
                type="submit"
                size="large"
                admin={true}
                htmlType="submit"
              >
                Register
              </RegisterButton2>

          </Form>
        </RegisterContainer>
  
      </HeroContainer>
    </Modal>
  );
};


