import React, { useEffect, useState } from "react";
import { Container, Button, Info } from "./style";
import { BigNav } from "../../globalComponents/Nav";
import { useDispatch, useSelector } from "react-redux";
import { showDrawer } from "../../globalComponents/slices/surveyModalSlice";
import { fetchTemplateSurvey, selectTemplateSurvey } from "./slice";
import { Row, Col, Pagination, Typography, Button as AntButton } from "antd";
import SurveyCard from "../Dashboard/SurveyCard";
import { useHistory } from "react-router";
import { ModalComponent } from "../../globalComponents/Modal";
import { SideEffect } from "../../Services";
import { notificationAlert } from "../../utils/notificationAlert";

const { Paragraph, Title } = Typography;

const TemplateSurvey = () => {
  const dispatcher = useDispatch();
  const { templateSurveys, paginationProps } =
    useSelector(selectTemplateSurvey);
  const history = useHistory();
  const [deleteSurveyProps, setDeleteSurveyProps] = useState({
    loading: false,
    visible: false,
    surveyId: 0,
  });

  console.log({ templateSurveys })
  useEffect(() => {
    (async () => {
      try {
        await dispatcher(fetchTemplateSurvey({ page: 1 }));
      } catch (error) {}
    })();
  }, []);

  const surveyCardArray = [...templateSurveys].map((survey, id) => (
    <Col xs={24} sm={12} md={6} key={survey.id}>
      <SurveyCard
        onDelete={() =>
          setDeleteSurveyProps((prevState) => ({
            ...prevState,
            surveyId: survey.id,
            visible: true,
          }))
        }
        onClick={() => {
          localStorage.templateId = survey.id;
          history.push("/dashboard/create-questionnaire");
        }}
        isTemplate
        questionNumber={survey.num_questions}
        questionTitle={survey.survey_name}
        respondents={survey.responses}
        active={survey.string_status === "Active"}
        id={survey.id}
      />
    </Col>
  ));

  const handlePagination = async (page, pageTotal) => {
    try {
      await dispatcher(fetchTemplateSurvey({ page: page }));
    } catch (error) {}
  };

  return (
    <>
      <BigNav title="Survey Templates" />
      {Boolean(templateSurveys.length) ? (
        <Row style={{ marginTop: "20px", paddingLeft: "10px" }} gutter={[6,6]}>
          {" "}
          {surveyCardArray}{" "}
        </Row>
      ) : (
        <Row
          style={{
            textAlign: "center",
            minHeight: "100vh",
          }}
          justify="center"
          align="middle"
        >
          <Col>
            <Button onClick={() => history.goBack()}>Go Back</Button>
            <Info>No available survey template</Info>
          </Col>
        </Row>
      )}

      <div style={{ display: "grid", placeItems: "center" }}>
        <Pagination
          onChange={handlePagination}
          defaultCurrent={1}
          total={10 * paginationProps.pages}
        />
      </div>
    </>
  );
};

export default TemplateSurvey;
