import { theme } from "../../globalAsset/theme";
import styled from "styled-components";
import { desktop } from "../../globalAsset/fontSize";

export const Forgot = styled.h5`
  ${"" /* line-height: 1px; */}
  ${"" /* margin: -15px 0; */}
  margin-bottom:15px;
  text-align: right;
  font-size: ${desktop.normalS};
  color: ${theme.grey};
  cursor: pointer;
`;
export const RegisterText = styled.h5`
  color: ${theme.grey};
  margin: 10px 0;
  text-align: center;
  margin: 20px 0;
  & > span {
    color: ${theme.lightgreen};
    cursor: pointer;
  }
`;

export const NotificationText = styled.h4`
  margin: 0;
  font-size: ${desktop.normal};
  text-align: center;
  color: black;
  span {
    font-weight: 600;
    color: ${theme.lightgreen};
  }
`;
