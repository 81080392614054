import  React from 'react';
import { SingleQuestionScaffold } from '../elements/singleQuestionScaffold';
import { MultiLineInput } from '../atom/SingleQuestion';

export const MultiLineQuestion = (props) => {
    return (
        <SingleQuestionScaffold questionId={props.questionObj.questionId} questionNumber={props.questionNumber} question={props.questionObj.question}>
            <MultiLineInput/>
        </SingleQuestionScaffold>
    );
};