import styled from "styled-components";

import { theme } from "../globalAsset/theme";
import React from "react";

export const Checkbox = (props) => {
  return <StyledBox required onChange={(e) => props.onChange} />;
};

const StyledBox = styled.input.attrs({ type: "checkbox" })`
  & .checked + label {
    border: 2px solid ${theme.lightgreen};
    color: ${theme.lightgreen};
    background: ${theme.lightgreen};
  }
`;
