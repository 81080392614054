import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Board } from "./Board";
import { SingleQuestionScaffold } from "./elements/singleQuestionScaffold";
import { RadioGroupQuestion } from "./molecule/RadioGroupQuestion";
import { SingleLineQuestion } from "./molecule/SingleLineQuestion";
import { MultiLineQuestion } from "./molecule/MultiLineQuestion";
import { DropDownQuestion } from "./molecule/DropDownQuestion";
import { CheckBoxQuestion } from "./molecule/CheckBoxQuestion";
import { MatrixQuestion } from "./molecule/MatrixQuestion";
import { useQuestionContext } from "./questionContext";
import { questionTypes } from "./questionTypes";
import { device } from "./../../../globalAsset/mediaQueries";
import { useSelector, useDispatch } from "react-redux";
//import { deleteAgentAction } from '../../../actions';
import { EditTemplate } from "./molecule/EditTemplateTitleForm";
import { Tabs, Tab } from "./atom/Tabs";
import { AddQuestionComponent } from "./compound/AddQuestionComponent";
import { EditQuestionBoard } from "./compound/EditQuestionBoard";

export const QuestionDashboard = (props) => {
  const { questionList } = useQuestionContext();
  const [tabObjState, setTabObjState] = useState([
    { name: "Add Questions", isActive: true },
    { name: "Edit Questions", isActive: false },
  ]);

  useEffect(() => {
    setTabObjState([
      { name: "Edit Questions", isActive: true },
      { name: "Preview Questions", isActive: false },
    ]);
  }, []);

  const handleTabClick = (name) => {
    const newTabs = tabObjState.slice().map((item) => {
      if (item.name === name) {
        item.isActive = true;
        return item;
      }
      item.isActive = false;
      return item;
    });
    setTabObjState(newTabs);
  };

  const tabsArray = tabObjState.map((item, index) => (
    <Tab
      key={index}
      isActive={item.isActive}
      onClick={() => handleTabClick(item.name)}
      titleText={item.name}
    />
  ));

  const questionListArray = [...questionList]?.map((item, index) => {
    switch (item.type) {
      case questionTypes.singleLine:
        return (
          <SingleLineQuestion
            key={index}
            questionObj={item}
            questionNumber={index + 1}
          />
        );
      case questionTypes.multipleLine:
        return (
          <MultiLineQuestion
            key={index}
            questionObj={item}
            questionNumber={index + 1}
          />
        );
      case questionTypes.checkBox:
        return (
          <CheckBoxQuestion
            key={index}
            questionObj={item}
            questionNumber={index + 1}
          />
        );
      case questionTypes.dropDown:
        return (
          <DropDownQuestion
            key={index}
            questionObj={item}
            questionNumber={index + 1}
          />
        );
      case questionTypes.radioGroup:
        return (
          <RadioGroupQuestion
            key={index}
            questionObj={item}
            questionNumber={index + 1}
          />
        );
      case questionTypes.matrix:
        return (
          <MatrixQuestion
            key={index}
            questionObj={item}
            questionNumber={index + 1}
          />
        );
      default:
        return null;
    }
  });

  return (
    <Board title="Questionnaire Dashboard">
      <StyledTitle>{props.surveyName}</StyledTitle>
      <Tabs tabs={tabsArray} />
      {tabObjState[0].isActive && <EditQuestionBoard isUnassigned={props.isUnassigned} />}
      {tabObjState[1].isActive && questionListArray}
    </Board>
  );
};

const StyledQuestionDashoboard = styled.div``;
const StyledTitle = styled.p`
  text-align: center;
  font-size: 18px;

  @media ${device.laptop} {
    font-size: 1.5vw;
  }
`;
