import React, { useState } from "react";
import { BigNav } from "../../../globalComponents/Nav";
import {
  Back,
  ComponentContainer,
  InnerContainer,
} from "../../../globalComponents/globalStyle";
import { useSelector } from "react-redux";
import { Checkbox } from "antd";
import { Steps, Popover } from "antd";
import { useHistory } from "react-router-dom";
import { useStateLga } from "../../../helperFunctions/useStateLga";
import { Form, Row, Col, Radio } from "antd";
import { Button, TextInput, ch } from "../../../globalComponents/Index";
import { device } from "../../../globalAsset/mediaQueries";
import { theme } from "../../../globalAsset/theme";
import styled from "styled-components";
import { desktop } from "../../../globalAsset/fontSize";
import { Component } from "react";
import { routes } from "../../../routes";
import { SideEffect } from "../../../Services";
import { notificationAlert } from "../../../utils/notificationAlert";
import { selectGlobalData } from "../../Dashboard/slice";
import { billingRate, getQuestionRange } from "../../../utils/questionRange";
import useScreenSize from "../../../utils/useSmallScreen";
import { screenSizes } from "../../../globalAsset/mediaQueries";
import { PrevNextButton } from "./stepContent";

const SampleNeeded = () => {
  return (
    <StyledForm
      extra="Please request for a minimum of 20 complete samples"
      name="samples"
    >
      <TextInput
        type="number"
        style={{ border: `1px solid ${theme.lightgreen}` }}
        required
      />
    </StyledForm>
  );
};

const QuestionLimit = ({ na }) => {
  return (
    <StyledForm name="questions">
      <Radio.Group>
        {Object.keys(billingRate).map((questionRange, index) => (
          <RadioButton key={index} value={questionRange} keys={questionRange} />
        ))}
      </Radio.Group>
    </StyledForm>
  );
};

const RadioButton = (props) => {
  const radioStyle = {
    textAlign: "left",
    display: "block",

    // height: "30px",
    // lineHeight: "30px",
  };
  return (
    <StyledRadio style={radioStyle} value={props.value} onClick={props.onClick}>
      {props.keys}
    </StyledRadio>
  );
};

const SampleBalanceProp2 = (props) => {
  const { isWithinScreenSize } = useScreenSize(screenSizes.tablet);
  const { activeSurveyId } = useSelector(selectGlobalData);
  const [current, setCurrent] = useState(0);
  // const [price, setPrice] = useState(0);
  const [limit, setLimit] = useState("");
  const [samples, setSamples] = useState(0);
  const history = useHistory();

  React.useEffect(() => {
    setCurrent(props.currentStep ?? 0);
  }, [props.currentStep]);
  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const questionRange = [
    { key: "1 – 5", value: 1000 },
    { key: "6 – 10", value: 1500 },
    { key: "11 – 15", value: 2000 },
    { key: "16 – 20", value: 2500 },
    { key: "21 – 30", value: 3000 },
    { key: "31 – 40", value: 4000 },
    { key: "41 – 50", value: 5000 },
  ];

  const { Step } = Steps;
  const customDot = (dot, { status, index }) => (
    <Popover
      content={
        <span>
          {/* step {index} status: {status} */}
          STEP {index + 1}
        </span>
      }
    >
      {dot}
    </Popover>
  );

  const handleQuestionRange = async (values) => {
    console.log("CALLED1", values);
    try {
      const upperLimit = values.questions.split(" ")[2];
      await SideEffect.addSurveyProperties({
        route: routes.rQuestionRange,
        data: { question_range: upperLimit, id: localStorage.surveyId },
      });
      console.log("CALLED2");
      notificationAlert(
        "success",
        "Question Limit",
        `${getQuestionRange(upperLimit)} was added as question limit`
      );
    } catch (error) {}
  };

  const handleSampleNeeded = async (values) => {
    try {
      if (parseInt(values.samples) < 20) {
        notificationAlert(
          "warning",
          "Sample Needed is too low",
          "Your number of complete samples is less than 20"
        );
      } else {
        await SideEffect.addSurveyProperties({
          route: routes.rSampleNeeded,
          data: {
            sample_needed: values.samples,
            id: localStorage.surveyId,
          },
        });
        notificationAlert(
          "success",
          "Sample Needed",
          `${values.samples} was added as sample needed`
        );

        setSamples(values.samples);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.message);
      }
    }
  };

  const surveyProperties = [
    /* {
      title: "Number of samples needed",
      id: 1,
      name: "samples",
      callback: handleSampleNeeded,
      component: <SampleNeeded />,
    }, */
    {
      title: "Number of questions",
      name: "questions",
      id: 2,
      callback: handleQuestionRange,
      component: <QuestionLimit />,
    },
  ];

  const StepsVariable = (
    <div>
      <Steps
        size="small"
        direction="horizontal"
        style={{
          paddingTop: "10px",
          marginLeft: "-3.250vw",
          // boxSizing: "border-box",
        }}
        progressDot={customDot}
        responsive
        current={current}
      >
        {surveyProperties.map((item, id) => (
          <Step
            size="small"
            key={id}
            // title={item.question}
          />
        ))}
      </Steps>
    </div>
  );

  const onFinish = async (values) => {
    try {
      await surveyProperties[current].callback(values);
      if (props.hideheaders) {
        props.next();
      } else if (current < surveyProperties.length - 1) {
        next();
      } else {
        history.push("/dashboard/survey-summary");
      }
    } catch (error) {}
  };

  const onFinishFailed = (errorInfo) => {
    // console.log(errorInfo);
  };
  return (
    <>
      {!props.hideheaders && <BigNav title="Survey Properties (2 of 2)" />}
      <ComponentContainer>
        <InnerContainer>
          {!props.hideheaders && <Back />}
          {!props.hideheaders && !isWithinScreenSize && StepsVariable}
          <Title> {surveyProperties[current].title}</Title>
          <Form
            style={{ width: isWithinScreenSize ? "100%" : "30%" }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            {surveyProperties[current].component}
            <PrevNextButton
              hideBack={!props.hideBack}
              back={props.back}
              buttonText={props.buttonText}
            />
          </Form>
        </InnerContainer>
      </ComponentContainer>
    </>
  );
};

const StyledRadio = styled(Radio)`
  color: ${theme.darkgreen};
`;

const Title = styled.h5`
  font-size: ${desktop.medium};
  font-weight: 600;
`;

const BackDiv = styled.div`
  background: ${theme.secondaryDark};
  height: 2.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  font-weight: 500;
  width: fit-content;
  padding: 0vw 0.7vw;
  border-radius: 3px;
`;

const StyledForm = styled(Form.Item)`
  & .ant-form-item-explain,
  .ant-form-item-extra {
    color: ${(props) => props.theme.color} !important;
    ${"" /* font-size: ${mobile.small} !important; */}
  }

  @media ${device.laptop} {
    & .ant-form-item-explain,
    .ant-form-item-extra {
      font-size: ${desktop.small} !important;
    }
  }

  @media ${device.desktop} {
    & .ant-form-item-explain,
    .ant-form-item-extra {
    }
  }
`;

export default SampleBalanceProp2;
