import { createSlice } from "@reduxjs/toolkit";
import { SideEffect } from "../../Services";



const templateSlice = createSlice({
    name: 'templateSlice',
    initialState: {
        templateSurveys: [],
        paginationProps: {pages: 0, total: 0},
    },
    reducers: {
        addTemplateSurveys: (state, {payload}) => {
            state.templateSurveys = payload;
        },
        addPaginationProps: (state, {payload}) => {
            state.paginationProps = payload;
        }
    }
})

export const { addTemplateSurveys, addPaginationProps } = templateSlice.actions;

export const fetchTemplateSurvey = payload => dispatcher => {
    return SideEffect.fetchSurveyTemplate(payload).then(
         response => {
            dispatcher(addTemplateSurveys(response.data.data.surveys));
            dispatcher(addPaginationProps({pages:response.data.data.pages, total: response.data.data.total_survey }))
            return Promise.resolve(response.data.data);
         },
         err => {
            if(err.response){
                return Promise.reject(err.response.data);
            }
         }
    )
}


export const selectTemplateSurvey = state => state.surveyTemplateReducer;

export default templateSlice.reducer