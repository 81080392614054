import { createSlice } from "@reduxjs/toolkit";
import { SideEffect } from "../../Services";
import { notificationAlert } from "../../utils/notificationAlert";

const surveySlice = createSlice({
  name: "surveySlice",
  initialState: {
    surveyProperties: {},
    singleSurvey: {},
  },
  reducers: {
    addSurveyProperties: (state, { payload }) => {
      state.surveyProperties = payload;
    },
    addSingleSurvey: (state, { payload }) => {
      state.singleSurvey = payload;
    },
    addTemplateToSurvey: (state, { payload }) => {
      const singleSurvey = payload.survey;
      const templateQuestionnaire = payload.template.map((template) => {
        template.isTemplate = true;
        return template;
      });
      // combine previously saved questions with the template questions
      singleSurvey.questionnaire = [
        ...singleSurvey.questionnaire,
        ...templateQuestionnaire,
      ];
      state.singleSurvey = singleSurvey;
      localStorage.removeItem("templateId");
    },
  },
});

export const { addSurveyProperties, addSingleSurvey, addTemplateToSurvey } =
  surveySlice.actions;

export const getSurveyProperties = (payload) => (dispatcher) => {
  return SideEffect.fetchSurveyProperties(payload)
    .then((response) => {
      console.log("getSurveyProperties", response);
      dispatcher(addSurveyProperties(response.data));
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      if (err.response) {
        return Promise.reject(err.response.data);
      }
    });
};

export const saveSingleQuestion = (payload) => (dispatcher) => {
  return SideEffect.saveSingleQuestion(payload)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      if (err.response) {
        return Promise.reject(err.response.data);
      }
    });
};

export const fetchSingleSurvey = (payload) => async (dispatcher) => {
  const response = await SideEffect.fetchSingleSurvey(payload);

  if (localStorage.templateId) {
    console.log({ fetchSingleSurvey: localStorage.templateId });
    const templateSurvey = await SideEffect.fetchSingleSurvey({
      surveyId: localStorage.templateId,
    });
    dispatcher(
      addTemplateToSurvey({
        template: templateSurvey.data.questionnaire,
        survey: response.data,
      })
    );
  } else {
    dispatcher(addSingleSurvey(response.data));
  }
};

export const deleteQuestion = (payload) => (dispatcher) => {
  return SideEffect.deleteQuestion(payload)
    .then((response) => {
      dispatcher(fetchSingleSurvey({ surveyId: localStorage.surveyId }));
      notificationAlert("success", "Question was deleted");
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      if (err.response) {
        notificationAlert("error", "Error occurred");
        return Promise.reject(err.response.data);
      }
    });
};

export const selectSurveySlice = (state) => state.surveyReducer;

export default surveySlice.reducer;
