import React from 'react';
import { SingleInput } from '../atom/SingleQuestion';
import { SingleQuestionScaffold } from '../elements/singleQuestionScaffold';

export const SingleLineQuestion = (props) => {
    return (
        <>
            <SingleQuestionScaffold  questionId={props.questionObj.questionId}  questionNumber={props.questionNumber} question={props.questionObj.question}>
                <SingleInput/>
            </SingleQuestionScaffold>    
        </>
    );
};