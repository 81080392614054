import { theme } from "../../globalAsset/theme";
import styled from "styled-components";
import { desktop } from "../../globalAsset/fontSize";

export const Forgot = styled.h5`
  margin-bottom: 15px;
  text-align: right;
  font-size: ${desktop.small};
  color: ${theme.grey};
  cursor: pointer;
`;

export const RegisterText = styled.h5`
  color: ${theme.grey};
  margin: 30px 0 10px 0;
  text-align: center;
  & > span {
    font-size: ${desktop.normalS};
    color: ${theme.lightgreen};
    cursor: pointer;
  }
`;
export const Text2 = styled.span`
  font-size: 11px;
  test-align: center;
  color: ${theme.lightgreen};
  cursor: pointer;
`;
export const Text1 = styled.h5`
  color: ${theme.grey};
  margin-left: 10px;
  font-size: 11px;
  font-weight: 600;
  & > span {
    color: ${theme.lightgreen};
    cursor: pointer;
  }
`;

export const NotificationText = styled.h4`
  margin: 0;
  font-size: ${desktop.normal};
  text-align: center;
  color: black;
  span {
    font-weight: 600;
    color: ${theme.lightgreen};
  }
`;


