import React, { useState } from "react";
import { Row, Col } from "antd";
import { DropDown } from "../../globalComponents/DropDown";
import { useStateLga } from "../../helperFunctions/useStateLga";
import { Checkbox } from "../../globalComponents/CheckBox";
import { notificationAlert } from "../../utils/notificationAlert";
import { useDispatch, useSelector } from "react-redux";
import withAuthentication from "../../HOC/withoutAuth";

import {
  AuthenticationLayout,
  TextInput,
  PasswordInput,
  Label,
  Button,
  ModalComponent,
} from "../../globalComponents/Index";
import { AiFillPhone } from "react-icons/ai";
import { IoPersonCircleSharp, IoLocationSharp } from "react-icons/io5";
import { Form as AntForm } from "antd";
import { useHistory } from "react-router-dom";
import { Text1, Text2, RegisterText, NotificationText } from "./Style";
import { MdEmail, MdLock } from "react-icons/md";
import {
  signUpSponsor,
  resendVerification as resendVerifictionLink,
} from "./slice";

const Register = () => {
  const [openModal, setOpenModal] = useState(false);
  const [email, setEmail] = useState("");
  const dispatcher = useDispatch();
  const history = useHistory();
  const [states, lgas, setStateLgas] = useStateLga();
  const closeModal = () => {
    setOpenModal(false);
    history.push("/");
  };

  const resendVerification = async (payload) => {
    try {
      await dispatcher(resendVerifictionLink(payload));
      notificationAlert("success", "Verificiation Sent");
    } catch (error) {
      notificationAlert("error", "Verificiation not Sent", error.message);
    }
  };
  const onFinish = async (values) => {
    // console.log(values);
    // history.push("/dashboard");
    try {
      const response = await dispatcher(signUpSponsor(values));
      if (response) {
        setOpenModal(true);
        setEmail(values.email);
      }
    } catch (error) {
      // console.log("this is error from outside", error);
      notificationAlert("error", "Registration Failed", error.message);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo);
  };
  return (
    <AuthenticationLayout>
      <h3 className="text-center fw-bolder mb-4">Sponsor Register</h3>
      <ModalComponent visible={ openModal} onCancel={null}>
        <div style={{ padding: "1vw", width: "100%" }}>
          <NotificationText>
            A verification link has been sent to <span>{email}</span>, kindly
            click on link to verify account
            <br />
            
          </NotificationText>
          <Row justify="space-between" style={{ marginTop: "1vw" }}>
              <Col md={{ span: 10 }}>
                <Button
                  onClick={() => resendVerification(email)}
                  text="Resend Verification"
                />
              </Col>
              <Col>
                <Button onClick={closeModal} text="Login" />
              </Col>
            </Row>
        </div>
      </ModalComponent>
      
      <AntForm
        style={{ paddingBottom: "1vw" }}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <AntForm.Item name="firstName" label={<Label><span className="text">First Name</span></Label>}>
              <TextInput required icon={<IoPersonCircleSharp />} />
            </AntForm.Item>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 11, offset: 1 }}>
            <AntForm.Item name="lastName" label={<Label><span className="text">Last Name</span></Label>}>
              <TextInput required icon={<IoPersonCircleSharp />} />
            </AntForm.Item>
          </Col>
        </Row>

        <AntForm.Item name="email" label={<Label><span className="text">Email</span> </Label>}>
          <TextInput type="email" required icon={<MdEmail />} />
        </AntForm.Item>
        <AntForm.Item name="password" label={<Label> <span className="text">Password</span> </Label>}>
          <PasswordInput required icon={<MdLock />} />
        </AntForm.Item>
        <AntForm.Item name="phone" label={<Label> <span className="text">Phone Number</span> </Label>}>
          <TextInput type="number" required icon={<AiFillPhone />} />
        </AntForm.Item>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <AntForm.Item name="state" label={<Label> <span className="text">State</span> </Label>}>
              <DropDown
                required
                values={states}
                icon={<IoLocationSharp />}
                onChange={(value) => setStateLgas(value)}
              />
            </AntForm.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 11, offset: 1 }}>
            <AntForm.Item name="lga" label={<Label> <span className="text">LGA</span> </Label>}>
              <DropDown values={lgas} required icon={<IoLocationSharp />} />
            </AntForm.Item>
          </Col>
        </Row>
        <div
          style={{
            marginTop: "0px",
            display: "flex",

            alignItems: "center",
          }}
        >
          <Text2>
            <Checkbox required />
          </Text2>
          <Text1 style={{ marginTop: "0px" }}>
            By registering I agree to SurveyPlus Software's{" "}
            <span onClick={() => history.push("/service-terms")}>Terms of Service and Privacy Policy</span>
          </Text1>
        </div>
        <Button style={{ margin: "10px 0" }} width="100%" text="GET STARTED" />
        <RegisterText>
          <span className="text1 text text-secondary">Already have an account? </span> 
          <span onClick={() => history.push("/")}> 
          <span className="text fw-bolder">Login here</span></span>
        </RegisterText>
      </AntForm>
    </AuthenticationLayout>
  );
};

export default withAuthentication(Register);
