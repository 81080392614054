import { createSlice } from "@reduxjs/toolkit";

export const surveyModalSlice = createSlice({
  name: "surveyModal",
  initialState: {
    visible: false,
    surveyName: null,
  },
  reducers: {
    showDrawer: (state) => {
      state.visible = true;
    },
    closeDrawer: (state) => {
      state.visible = false;
    },
    editSurveyName: (state, { payload }) => {
      state.surveyName = payload;
    },
  },
});

export const { showDrawer, closeDrawer, survey, editSurveyName } =
  surveyModalSlice.actions;
export default surveyModalSlice.reducer;
