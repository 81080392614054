// export const inActiveShadow = "#00000019";
// export const activeShadow = "#00000009";
// export const activeShadow2 = "#00000019";
// export const activeShadow3 = "#00000029";
export const shadow = "0px 3px 10px -1px rgba(50, 50, 71, 0.1)";
export const grey1 = "#00000069";
export const grey2 = "#00000089";
export const darkgreen = "#074F19";
export const lightgreen = "#259B01";
export const lightgreen1 = "#259B01";
export const lightgreen2 = "#259B01";
export const darkorange = "#E96F06";
export const lightorange = "#F1AE02";
export const white = "white";
export const black = "black";
// export const background = "#f7fafc";
export const background = "";

export const theme = {
  shadow: "0px 3px 6px -1px rgba(50, 50, 71, 0.1)",
  foreground: "#ffffff",
  grey: "#00000069",
  subtleGrey: "#E8E8E8",
  color: "#000000",
  subtleBlack: "#C6CDD6",
  lightOrange: "#F1AE02",
  darkorange: "#E96F06",
  background: "#f2f2f2",
  // background: "#f7fafc",
  darkgreen: "#074F19",
  lightgreen: "#259B01",
  secondaryDark: "#940056",
};
