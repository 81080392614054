import React from "react";
import { useHistory, Link } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../globalAsset/theme";
import { FaLessThanEqual, FaWallet } from "react-icons/fa";
import { RiDashboardFill } from "react-icons/ri";
import { CgProfile } from "react-icons/cg";
import { MdLandscape } from "react-icons/md";
import { IoCreate } from "react-icons/io5";
import Logo from "../assets/surveypluslogo.svg";
import useScreenSize from "../utils/useSmallScreen";
import { device, screenSizes } from "../globalAsset/mediaQueries";
import { useSidebarContext } from "./SideBarProvider";

// import { NavLink, useRouteMatch,  } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";
import MobileSideMenu from "./MobileSideMenu";

const SideMenu = () => {
  const { isWithinScreenSize } = useScreenSize(screenSizes.tablet);
  const { openSidebar, setOpenSidebar } = useSidebarContext();
  const routes = [
    {
      id: 1,
      icon: <RiDashboardFill />,
      name: "Dashboard",
      route: "/dashboard",
    },
    {
      id: 4,
      icon: <MdLandscape />,
      name: "Survey Archive",
      route: "/dashboard/survey-archive",
    },
    {
      id: 2,
      icon: <CgProfile />,
      name: "My Profile",
      route: "/dashboard/profile",
    },
  ];
  const history = useHistory();
  const navLink = routes.map((link, index) => (
    <StyledNavLink key={index} exact to={link.route}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div className="linkIcon"> {link.icon}</div>
        <div className="linkName">
          <span className="fs-6">{link.name}</span>
        </div>
      </div>
    </StyledNavLink>
  ));

  const handleLogout = () => {
    localStorage.removeItem("token");
    history.push("/");
  };

  if (!isWithinScreenSize) {
    return (
      <Container className="cont">
        <RouteContainer>
          <div>
            <LogoContainer>
              <LogoImage
                onClick={() => history.push("/dashboard")}
                src={Logo}
              />
            </LogoContainer>
            {navLink}
          </div>
          <StyledNavLink style={{ color: "red" }} onClick={handleLogout}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="linkIcon">
                <FiLogOut />{" "}
              </div>
              <div className="linkName">Logout</div>
            </div>
          </StyledNavLink>
        </RouteContainer>
      </Container>
    );
  }

  return (
    <MobileSideMenu
      visible={openSidebar}
      onClose={() => {
        setOpenSidebar(false);
      }}
      title={
        <LogoContainer>
          <LogoImage onClick={() => history.push("/dashboard")} src={Logo} />
        </LogoContainer>
      }
    >
      <RouteContainer>
        <div>{navLink}</div>

        <StyledNavLink style={{ color: "red" }} onClick={handleLogout}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="linkIcon">
              <FiLogOut />{" "}
            </div>
            <div className="linkName">Logout</div>
          </div>
        </StyledNavLink>
      </RouteContainer>
    </MobileSideMenu>
  );
};

export default SideMenu;

const Container = styled.div`
  width: 17vw;
  position: sticky;
  overflow: auto;
  top: 0;
  left: 0;
  text-align: center;
  height: 100vh;
  font-size: 1vw;
  ${"" /* padding-top: 2vw; */}
  background: ${theme.foreground};
  ${"" /* background: red; */}
  box-shadow: ${theme.shadow};

  @media (max-width: 567px) {
    width: 42vw;
  }
`;

const LogoImage = styled.img`
  cursor: pointer;
  height: auto;
  width: 100%;
`;

const LogoContainer = styled.div`
  padding: 15px;
  width: 80%;
`;
const RouteContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const StyledNavLink = styled(Link)`
  color: black;
  margin: 1.62vw 0.58vw;
  display: block;
  text-align: start;
  color: grey;
  .linkIcon {
    margin: 0 10px 0 0;
    font-size: 1.5vw;
    display: flex;
    align-items: center;
  }
  .linkName {
    margin: 0;
    font-size: 1.15vw;
    font-weight: 500;
  }
  &.active {
    transition: all 0.7s;
    color: ${theme.lightgreen};
    font-weight: bold;
  }
  &:hover {
    color: ${theme.lightgreen};
  }
  @media (max-width: 567px) {
    margin: 1.62vw 0.58vw;
    .linkName {
      margin: 0;
      font-size: 4.15vw;
      font-weight: 500;
    }
    .linkIcon {
      margin: 0 7px 0 0;
      font-size: 3.7vw;
      display: flex;
      align-items: center;
    }
  }
`;
