import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { FiCheck } from "react-icons/fi";
import { device } from "../../../../globalAsset/mediaQueries";
import { useQuestionContext } from "../questionContext";
import { useDispatch } from "react-redux";
import { theme } from "../../../../globalAsset/theme";
import { Checkbox, Radio, Input, Select } from "antd";
import { questionTypes } from "../questionTypes";

const { Option } = Select;

const { TextArea } = Input;
const CheckboxGroup = Checkbox.Group;

const CheckboxOption = (props) => {
  const optionList = props?.options?.map((item, index) => {
    return (
      <div key={index}>
        <Checkbox value={item}>{item}</Checkbox>
        <br />
      </div>
    );
  });

  return <CheckboxGroup onChange={props.onChange}>{optionList}</CheckboxGroup>;
};

const RadioGroupOption = (props) => {
  const optionList = props.options?.map((item, index) => {
    return (
      <div key={index}>
        <Radio value={item}>{item}</Radio>
        {/* <br/> */}
      </div>
    );
  });

  return (
    <Radio.Group onChange={props.onChange} /*  value={props.value} */>
      {optionList}
    </Radio.Group>
  );
};

export const ScreeningQuestionAnswer = (props) => {
  const { questionList, setQuestion, screeningAnswerProps,
    setScreeningAnswerProps } = useQuestionContext();
  const dispatcher = useDispatch();
  const screeningQuestion = useMemo(() => questionList.at(0), [questionList]);


  const handleOptionsChange = (value) => {
    if(screeningQuestion.type === questionTypes.checkBox){
        setScreeningAnswerProps(prev => ({...prev, answer: value}))
    }

    if(screeningQuestion.type === questionTypes.radioGroup){
        setScreeningAnswerProps(prev => ({...prev, answer: [`${value.target.value}`]}))
    }
  }

  return (
    <StyledSingleQuestionScaffold>
      <StyledHeaderContainer>
        <StyledHeader>
          <p>{screeningQuestion?.question}</p>
        </StyledHeader>
        {screeningQuestion?.isSaved && <StyledFiCheck />}
      </StyledHeaderContainer>
      <StyledBody>
        {screeningQuestion?.type === questionTypes?.radioGroup && (
          <RadioGroupOption options={screeningQuestion.options} onChange={handleOptionsChange} />
        )}{" "}
        {screeningQuestion?.type === questionTypes.checkBox && (
          <CheckboxOption options={screeningQuestion.options} onChange={handleOptionsChange} />
        )}
      </StyledBody>
    </StyledSingleQuestionScaffold>
  );
};

const StyledSingleQuestionScaffold = styled.div`
  margin-bottom: 10px;
  background-color: ${(props) => (props.isSelect ? "#EDF2F7" : "#fff")};
  color: #414141;
  cursor: pointer;

  & p {
    font-size: 16px;
  }

  @media ${device.laptop} {
    & p {
      font-size: 1.1vw;
    }
  }
`;

const StyledBody = styled.div`
  width: 100%;
  padding: 0 10px;
  padding-bottom: 10px;

  @media ${device.laptop} {
    width: 100%;
  }
`;

const StyledHeader = styled.div`
  display: flex;
  padding: 10px 10px 0px 10px;
`;

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
`;

const StyledFiCheck = styled(FiCheck)`
  color: ${theme.lightgreen};
  font-weight: bolder;
  font-size: 24px;

  @media ${device.laptop} {
    font-size: 1.8vw;
  }
`;
