import { Input } from "antd";
import styled from "styled-components";
import { theme } from "../globalAsset/theme";
import { device } from "../globalAsset/mediaQueries";
import { mobile, desktop } from "../globalAsset/fontSize";
import { Select } from 'antd';

export const DropDown = ({ onChange, icon, values, ...props }) => {
  console.log({ values })
  return (
    /* <InputDiv>
      <IconContainer>{icon}</IconContainer>
      <Dropdown onChange={onChange}>
        <Option></Option>
        {values.map((value) => (
          <Option key={value.key} value={value.text}>
            {value.text}
          </Option>
        ))}
      </Dropdown>
    </InputDiv> */

    <Select onChange={onChange} options={values.map(value => ({ value: value.text, label: value?.label ?? value.text }))} />
  );
};

const Dropdown = styled.select`
  &&& {
    outline: none;
    border: none;
    background: transparent;
    width: 90%;
    font-size: 14px;
    color: black;

    &:focus,
    &:active {
      outline: none;
      border: none;
    }
  }
`;
const IconContainer = styled.div`
  font-size: 1.2vw;
  margin: 0;
  color: ${theme.grey};
  display: flex;
  align-items: center;
`;
const Option = styled.option`
  background-color: ${theme.shadow} !important;
`;

const InputDiv = styled.div`
  width: 100%;
  height: 40px;
  ${"" /* border: 1px solid ${theme.subtleBlack}; */}
  border-radius: 4px;
  padding: 0vw 0.5vw;
  display: flex;
  margin: 0;
  background: ${theme.subtleGrey};
  align-items: center;
  :active,
  :focus,
  :hover {
    border: 2px solid ${theme.lightgreen};
  }
  @media ${device.laptop} {
    height: 2.3vw;
    align-items: center;

    :active,
    :focus,
    :hover {
      border: 1px solid ${theme.lightgreen};
    }
  }
`;
