import { ConsoleSqlOutlined } from "@ant-design/icons";
import axios from "axios";
import { domain } from "./domain";
import { getFormData } from "./utils/getFormData";
import {
  registerSponsorRoute,
  resendVerificationRoute,
  sponsorLoginRoute,
  forgotPasswordRoute,
  fetchUserInfoRoute,
  addAddress,
  addSponsorType,
  addLocation,
  addProfilePic,
  fetchAssaignedSurvey,
  routes,
} from "./routes";

const authAxios = axios.create({
  baseURL: domain,
  //headers: { Authorization: `Token ${localStorage.token}` },
});

export class SideEffect {
  static registerSponsor(payload) {
    const { firstName, lastName, email, password, phone, state, lga } = payload;
    let bodyFormData = new FormData();
    bodyFormData.append("full_name", `${firstName} ${lastName}`);
    bodyFormData.append("email", email.toLowerCase());
    bodyFormData.append("password", password.toLowerCase());
    bodyFormData.append("phone", phone);
    bodyFormData.append("state", state);
    bodyFormData.append("lga", lga);
    return axios({
      method: "POST",
      url: `${domain}${registerSponsorRoute}`,
      data: bodyFormData,
    }).then((res) => res);
  }

  static resendVerificationLink(payload) {
    let bodyFormData = new FormData();
    bodyFormData.append("email", payload);
    return axios({
      method: "POST",
      url: `${domain}${resendVerificationRoute}`,
      data: bodyFormData,
    }).then((response) => response);
  }

  static loginUser(payload) {
    const { email, password } = payload;
    let bodyFormData = new FormData();
    bodyFormData.append("email", email.toLowerCase());
    bodyFormData.append("password", password);
    return axios({
      method: "post",
      url: `${domain}${sponsorLoginRoute}`,
      data: bodyFormData,
    }).then((res) => res);
  }

  static forgotPassword(payload) {
    // const { email } = payload;
    let bodyFormData = new FormData();
    bodyFormData.append("email", payload.email.toLowerCase());
    return axios({
      method: "POST",
      url: `${domain}${forgotPasswordRoute}`,
      data: bodyFormData,
    }).then((response) => response);
  }

  static fetchUserInfo() {
    return axios({
      method: "GET",
      url: `${domain}${fetchUserInfoRoute}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    }).then((response) => response);
  }

  static updateSponsorType(payload) {
    // const { settlement } = payload;
    let bodyFormData = new FormData();
    bodyFormData.append("sponsor_type", `${payload}`);
    console.log(payload);
    return axios({
      method: "POST",
      url: `${domain}${addSponsorType}`,
      data: bodyFormData,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    }).then((response) => response);
  }

  static updateAddress(payload) {
    // const { settlement } = payload;
    let bodyFormData = new FormData();
    bodyFormData.append("address", `${payload}`);
    return axios({
      method: "POST",
      url: `${domain}${addAddress}`,
      data: bodyFormData,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    }).then((response) => response);
  }

  static updateLocation(payload) {
    const { state, lga } = payload;
    let bodyFormData = new FormData();
    bodyFormData.append("state", `${state}`);
    bodyFormData.append("lga", `${lga}`);
    // console.log("the info is", bodyFormData);
    return axios({
      method: "POST",
      url: `${domain}${addLocation}`,
      data: bodyFormData,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    }).then((response) => response);
  }

  static updateProfilePic(payload) {
    let bodyFormData = new FormData();
    bodyFormData.append("profile_image", payload);
    return axios({
      method: "POST",
      url: `${domain}${addProfilePic}`,
      data: bodyFormData,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    }).then((response) => response);
  }

  static fetchAssaignedSurvey(payload) {
    return axios({
      method: "GET",
      url: `${domain}${fetchAssaignedSurvey}${payload}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    }).then((response) => response);
  }

  static async createSurvey(payload) {
    let bodyFormData = new FormData();
    bodyFormData.append("name", payload.data.name);
    bodyFormData.append("respondent_type", payload.data.respondent_type);
    bodyFormData.append("sample_needed", payload.data.sample_needed);
    if (payload.data.respondent_type === "unassigned") {
      bodyFormData.append(
        "respondent_incentive_amount",
        payload.data.respondent_incentive_amount
      );
    }
    const data = getFormData(payload.data);
    return authAxios({
      method: "POST",
      url: routes.rAddSurvey,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
      data: data,
    });
  }

  static async addSurveyProperties(payload) {
    return authAxios({
      method: "POST",
      url: payload.route,
      data: getFormData(payload.data),
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    });
  }

  static async fetchSurveyProperties(payload) {
    return authAxios({
      method: "GET",
      url: `${routes.rFetchSurveyProperties}${payload.surveyId}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    });
  }

  static async saveSingleQuestion(payload) {
    const formData = getFormData(payload.data);
    console.log({ formData })
    return authAxios({
      method: "POST",
      url: `${routes.rSaveSingleQuestionObject}`,
      data: formData,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    });
  }

  static async fetchSingleSurvey(payload) {
    return authAxios({
      method: "GET",
      url: `${routes.rFetchSingleSurvey}${payload.surveyId}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    });
  }

  static async fundSurvey(payload) {
    return authAxios({
      method: "POST",
      url: routes.rFundSurvey,
      data: getFormData(payload.data),
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    });
  }

  static async sendSurvey(payload) {
    return authAxios({
      method: "POST",
      url: routes.rConfirmSurvey,
      data: getFormData(payload.data),
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    });
  }

  static async checkSurveyAvailability(payload) {
    return authAxios({
      method: "POST",
      url: routes.rConfirmSurvey,
      data: getFormData(payload.data),
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    });
  }

  static async fetchPurchasedSurvey(payload) {
    return axios({
      method: "GET",
      url: `${domain}${routes.rFetchPurchasedSurvey}${payload.page}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    });
  }

  static async fetchAchiveSurvey(payload) {
    return axios({
      method: "GET",
      url: `${domain}${routes.rFetchSurvey}${payload.page}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    });
  }

  static async fetchSurveyAnalysis(payload) {
    return authAxios({
      method: "POST",
      url: routes.rFetchSurveyAnalysis,
      data: getFormData(payload.data),
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    });
  }

  static async fetchSurveyTemplate(payload) {
    return authAxios({
      method: "GET",
      url: `${routes.rFetchTemplates}${payload.page}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    });
  }

  static async deleteSurvey(payload) {
    return authAxios({
      method: "GET",
      url: `${routes.rDeleteSingleSurvey}${payload.surveyId}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    });
  }

  static async deleteQuestion(payload) {
    return authAxios({
      method: "GET",
      url: `${routes.rDeleteSingleQuestion}${payload.questionId}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    });
  }
}
