import React, { useState } from "react";
import { Form as AntForm, Row, Col } from "antd";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { MdEmail, MdLock } from "react-icons/md";
import { Forgot, RegisterText, NotificationText } from "./style";
import { signInSponsor, forgotPasword } from "./slice";
import { resendVerification as resendVerifictionLink } from "../Register/slice";
import withAuthentication from "../../HOC/withoutAuth";
import "./Index.css";
import {
  AuthenticationLayout,
  TextInput,
  PasswordInput,
  Label,
  Button,
  ModalComponent,
} from "../../globalComponents/Index";
import { notificationAlert } from "../../utils/notificationAlert";
const Login = () => {
  const [verificationModal, setVerificationModal] = useState(false);

  const [forgotModal, setForgotModal] = useState(false);
  const [email, setEmail] = useState("");
  const dispatcher = useDispatch();

  const resendVerification = async (payload) => {
    try {
      await dispatcher(resendVerifictionLink(payload));
      setVerificationModal(false);
      notificationAlert("success", "Verificiation Sent");
    } catch (error) {
      setVerificationModal(false);
      notificationAlert("error", "Verificiation not Sent", error.message);
    }
  };

  const handleForgotPassword = async (value) => {
    console.log("entered value", value);
    try {
      const response = await dispatcher(forgotPasword(value));
      setForgotModal(false);
      console.log(response);
      notificationAlert("success", "Reset Sent", response.message);
    } catch (error) {
      setForgotModal(false);
      notificationAlert("error", "Error", error.message);

      console.log("verification error", error);
    }
  };

  const onFinish = async (values) => {
    console.log(values);
    setEmail(values.email);
    try {
      const response = await dispatcher(signInSponsor(values));
      console.log({ response });
      if (response.user_type !== "business") {
        notificationAlert(
          "error",
          "Invalid user type",
          "Please sign in with a business account"
        );
        return;
      }
      if (response) {
        if (response.verified === 1) {
          localStorage.setItem("token", response.token);
          history.replace("/dashboard");
        } else if (response.verified === 0) {
          setVerificationModal(true);
        }
      }
    } catch (error) {
      console.log("the error", error);
      notificationAlert("error", "Error", error.message);
    }
    // console.log(values);
    // history.push("/dashboard");
  };
  function refreshPage() {
    /* const timer = setTimeout(() => {
      window.location.reload(false);
    }, 700); */
  }

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
  };
  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo);
  };
  const history = useHistory();
  return (
    <AuthenticationLayout>
      <h3 className="text-center fw-bolder">Sponsor Login</h3>
      <ModalComponent
        visible={ verificationModal}
        onCancel={() => setVerificationModal(false)}
      >
        <div style={{ padding: "1vw", width: "100%" }}>
          <NotificationText>
            A verification link will be sent to <span>{email}</span>, kindly
            click on link to verify account
            <br />
          </NotificationText>
          <Row justify="space-between" style={{ marginTop: "1vw" }}>
            <Col md={{ span: 10 }}>
              <Button
                onClick={() => resendVerification(email)}
                text="Resend Verification"
              />
            </Col>
          </Row>
        </div>
      </ModalComponent>
      <ModalComponent
        visible={forgotModal}
        onCancel={() => setForgotModal(false)}
      >
        <div style={{ padding: "1vw", width: "100%" }}>
          <AntForm
            // {...layout}
            layout="vertical"
            onFinish={handleForgotPassword}
            onFinishFailed={onFinishFailed}
          >
            <AntForm.Item name="email" label={<Label>Email</Label>}>
              <TextInput required icon={<MdEmail />} />
            </AntForm.Item>

            <Button width="100%" text="Reset Password" />
          </AntForm>
        </div>
      </ModalComponent>
      <AntForm
        // {...layout}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <AntForm.Item
          name="email"
          label={
            <Label>
              <span className="text">Email</span>
            </Label>
          }
        >
          <TextInput required icon={<MdEmail />} />
        </AntForm.Item>
        <AntForm.Item
          name="password"
          label={
            <Label>
              <span className="text">Password</span>{" "}
            </Label>
          }
        >
          <PasswordInput required icon={<MdLock />} />
        </AntForm.Item>
        <Forgot onClick={() => setForgotModal(true)}>
          <p className="fs-6">Forgot Password</p>
        </Forgot>
        <Button onClick={refreshPage} width="100%" text="LOGIN" />
        <RegisterText>
          <span className="text text-secondary">Don't have an account? </span>{" "}
          <span onClick={() => history.push("/register")}>
            <span className="text">Create a new account.</span>
          </span>
        </RegisterText>
      </AntForm>
    </AuthenticationLayout>
  );
};

export default withAuthentication(Login);
