import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { SideEffect } from "../../Services";
export const updateImage = createAsyncThunk(
  "updateInfo/image",
  async (payload, { rejectWithValue }) => {
    try {
      const updateImage = await SideEffect.updateProfilePic(payload);
      // console.log("the payload", payload);
      console.log(updateImage);
      return updateImage;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

const updateInfoSlice = createSlice({
  name: "updateInfo",
  initialState: {
    data: {},
    image: {},
    success: false,
  },
  reducers: {
    userInfo: (state, { payload }) => {
      state.data = payload;
    },
    addImage: (state, { payload }) => {
      state.image = payload;
    },
  },
  extraReducers: {
    [updateImage.fulfilled]: (state, action) => {
      state.success = true;
    },
    [updateImage.pending]: (state, { payload }) => {
      state.success = false;
    },
    [updateImage.rejected]: (state, { error }) => {
      state.success = false;
    },
  },
});
export default updateInfoSlice.reducer;
export const { userInfo, addImage } = updateInfoSlice.actions;

export const updateSponsorType = (payload) => (dispatcher) => {
  return SideEffect.updateSponsorType(payload)
    .then((response) => {
      //   dispatcher(userInfo(response.data.data));
      console.log("the response from update", response.data);
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      if (error.response) {
        console.log("fetch user error", error.response);
        return Promise.reject(error.response.data);
      }
    });
};

export const updateAddress = (payload) => (dispatcher) => {
  return SideEffect.updateAddress(payload)
    .then((response) => {
      //   dispatcher(userInfo(response.data.data));
      console.log("the response from update", response.data);
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      if (error.response) {
        console.log("fetch user error", error.response);
        return Promise.reject(error.response.data);
      }
    });
};

export const updateLocation = (payload) => (dispatcher) => {
  return SideEffect.updateLocation(payload)
    .then((response) => {
      //   dispatcher(userInfo(response.data.data));
      console.log("the response from update", response.data);
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      if (error.response) {
        console.log("fetch user error", error.response);
        return Promise.reject(error.response.data);
      }
    });
};
