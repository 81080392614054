import { useState } from "react";
import { states } from "../constants/states";

export const useStateLga = () => {
  const allLgas = {};
  const allStates = states
    .map((state) => {
      allLgas[state.name] = state.lgas.map((lga) => ({
        key: lga._id,
        text: lga.name,
        value: state.name,
      }));
      return {
        key: state.name,
        text: state.name,
        value: state.name,
      };
    })
    .sort(({ text }, { text: bText }) => text.localeCompare(bText));
  const [lgas, setLgas] = useState([]);
  const setStateLgas = (state) => setLgas(allLgas[state]);
  return [allStates, lgas, setStateLgas];
};
