import React, { useState } from "react";
import styled from "styled-components";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";

import { useHistory } from "react-router-dom";
import { desktop } from "../globalAsset/fontSize";
import { device } from "../globalAsset/mediaQueries";
import { theme } from "../globalAsset/theme";
import { Menu, Dropdown, Button } from "antd";
import { AiFillCaretDown } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { FiAlignRight } from "react-icons/fi";
import useScreenSize from "../utils/useSmallScreen";
import { screenSizes } from "../globalAsset/mediaQueries";
import { useSidebarContext } from "./SideBarProvider";

export const BigNav = ({ title }) => {
  const userInfo = useSelector((state) => state.globalData.data);
  const { isWithinScreenSize } = useScreenSize(screenSizes.tablet);
  const { openSidebar, setOpenSidebar } = useSidebarContext();



  const history = useHistory();
  const handleLogOut = () => {
    history.push("/");
    localStorage.removeItem("token");
  };
  const menu = (
    <Menu style={{ width: "100px" }}>
      <MenuItem onClick={() => history.push(`/dashboard/profile`)}>
        <span style={{ marginLeft: "5px" }}>Profile</span>
      </MenuItem>
      <MenuItem danger onClick={() => handleLogOut()}>
        <span style={{ marginLeft: "5px" }}>LogOut</span>
      </MenuItem>
    </Menu>
  );
  return (
    <Container>
      <h4>
        {title}
      </h4>
      {isWithinScreenSize && (
        <Button
          icon={<FiAlignRight />}
          onClick={() => {
            setOpenSidebar(true);
          }}
        />
      )}
      {!isWithinScreenSize && (
        <div style={{ display: "flex", alignItems: "center" }}>
          {userInfo?.profile_pic ? (
            <StyledUserImage
              src={`data:image/png;base64,${userInfo?.profile_pic}`}
            />
          ) : (
            <div>
              <Avatar icon={<UserOutlined />} />
            </div>
          )}

          <div style={{ marginLeft: "10px" }}>
            <UserName>
              {userInfo?.firstname} {userInfo?.lastname}
            </UserName>
            <UserTitle>Business Account</UserTitle>
          </div>
          <StyledDropdown overlay={menu}>
            <StyledIcon />
          </StyledDropdown>
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
  background: ${theme.foreground};
  position: sticky;
  top: 0;
  z-index: 2;
  right: 0;
  border: ${theme.shadow};
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  & > h4 {
    margin: 0;
    font-size: ${desktop.normal};
    font-weight: 500;
  }
  @media ${device.tablet} {
    & > h4 {
      font-size: ${desktop.large};
    }
  }
`;
const MenuItem = styled(Menu.Item)`
  font-size: ${desktop.normalS};
  display: flex;
  align-items: center;
`;
const StyledIcon = styled(AiFillCaretDown)`
  color: ${(props) => props.theme.color};
  cursor: pointer;
  margin: 0 10px;
`;

const StyledDropdown = styled(Dropdown)`
  font-size: 1vw;
`;
const StyledUserImage = styled.img`
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  border: none;
  outline: none;
  border-radius: 100%;
  margin: 0 0.65vw;
  height: 2.7vw;
  width: 2.7vw;
  ${"" /* width: */}
`;
const UserName = styled.h5`
  text-align: start;
  color: ${theme.grey};
  font-size: 0.91vw;
  font-weight: bold;
`;
const UserTitle = styled.h4`
  text-align: start;
  color: ${theme.lightgreen};
  font-style: italic;
  line-height: 0.065vw;
  font-size: 0.7vw;
  margin-top: -0.32vw;
`;
