import { Input } from "antd";
import styled from "styled-components";
import { theme } from "../globalAsset/theme";
import { device } from "../globalAsset/mediaQueries";
import { mobile, desktop } from "../globalAsset/fontSize";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';


export const TextInput = ({ icon, max, ...props }) => {
  return (
    <Input prefix={icon} maxLength={max} {...props} size="large"  />
    /*  <InputDiv style={{ ...style }}>
       <IconContainer>{icon}</IconContainer>
       <StyledInputBorderless
         bordered={false}
         required={required === 0 ? false : true}
         type={type}
         maxLength={max}
         disabled={disabled ? true : false}
         name={name}
         value={value !== null && value}
         // bordered={false}
         placeholder={placeholder}
         onChange={onChange}
         validateStatus
         helperText
       />
     </InputDiv> */
  );
};

export const TextInputDisplay = ({ icon, max, ...props }) => {
  return (
    <Input prefix={icon} maxLength={max} {...props} size="large"  />
    /*  <InputDiv>
       <IconContainer>{icon}</IconContainer>
       <StyledInputBorderless
         bordered={false}
         required={required === 0 ? false : true}
         type={type}
         disabled={disabled ? true : false}
         maxLength={max}
         name={name}
         value={value}
         // bordered={false}
         placeholder={placeholder}
         onChange={onChange}
         validateStatus
         helperText
       />
     </InputDiv> */
  );
};

export const PasswordInput = ({ icon, max, ...props }) => {
  return (
    <Input.Password
      prefix={icon}
      maxLength={max}
      iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
      {...props}
      size="large" 
    />
    /*  <InputDiv>
       <IconContainer>{icon}</IconContainer>
 
       <StylePasswordBorderless
         bordered={false}
         type="password"
         required
         maxLength={max}
         name={name}
         // bordered={false}
         placeholder={placeholder}
         onChange={onChange}
       />
     </InputDiv> */
  );
};


