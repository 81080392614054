import styled from "styled-components";
import { Row, Col } from "antd";
import { device } from "../globalAsset/mediaQueries";
import { theme } from "../globalAsset/theme";
import { desktop } from "../globalAsset/fontSize";
import Logo from "../assets/logo.png";
import { LoginSvg } from "./LoginSvg";
import useScreenSize from "../utils/useSmallScreen";
import { screenSizes } from "../globalAsset/mediaQueries";

export const AuthenticationLayout = ({ children }) => {
  const { isWithinScreenSize } = useScreenSize(screenSizes.mobileL);
  return (
    <Container>
      <Row>
        {!isWithinScreenSize && (
          <Col xs={{ span: 0 }} md={{ span: 10 }}>
            <LeftContent />
          </Col>
        )}
        <Col xs={{ span: 24 }} md={{ span: 14 }}>
          <Layout>
            <LogoContainer src={Logo} />
            <RightContainer>{children}</RightContainer>
          </Layout>
        </Col>
      </Row>
    </Container>
  );
};

const LeftContent = () => {
  return (
    <LeftContainer>
      <div>
        <LoginSvg />
        <h3 className="mt-3 text-center fw-bolder">
          Data and Insights Delivered.
        </h3>
        <p className="text-center">
          We deliver data and actionable insights to organizations and
          researchers in a timely and cost-effective manner.
        </p>
      </div>
    </LeftContainer>
  );
};

export const Layout = styled.div`
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  height: 100vh;
  border: none;
  background: ${theme.foreground};
  position: relative;
  @media ${device.laptop} {
    height: 100vh;
  }
`;

const LogoContainer = styled.img`
  height: 30vh;
  background: none;
  cursor: pointer;
`;

export const RightContainer = styled.div`
  min-height: 40%;
  border-radius: 5px;
  @media ${device.laptop} {
    width: 100%;
    min-height: 40vh;
    padding: 1vw 6vw;
    padding-bottom: 2vw;
    background: ${theme.foreground};
    text-align: left;
    margin-bottom: 2vw;
  }
`;

export const LeftContainer = styled.div`
  display: none;
  width: 100%;
  height: 100vh;
  background: ${theme.subtleGrey};
  padding: 0 5vw;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${device.tablet} {
    display: block;
  }
`;

const Container = styled.div`
  background: ${theme.background};
  width: 100%;

  @media ${device.desktopL} {
    display: flex;
    max-width: 1536px;
    margin: 0 auto;
  }
`;
