import React from "react";
import styled from "styled-components";
import { desktop } from "../globalAsset/fontSize";
import { device } from "../globalAsset/mediaQueries";
import { BsArrowLeft } from "react-icons/bs";

import { theme } from "../globalAsset/theme";
import { useHistory } from "react-router-dom";
export const Back = () => {
  const history = useHistory();
  return (
    <BackContainer onClick={() => history.goBack()}>
      <div>
        <BsArrowLeft />
      </div>
      <h5>back</h5>
    </BackContainer>
  );
};

export const ComponentContainer = styled.div`
  max-width: 100%;
  min-height: 100vh;

  @media ${device.laptop} {
    padding: 15px;
  }
`;

export const InnerContainer = styled.div`
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  min-height: 100vh;
  padding: 15px;
`;
export const Label = styled.h3`
  font-size: ${desktop.normal};
  color: ${theme.color};
  margin: 0;
  font-weight: 500;
`;
export const BackContainer = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 5px;

  margin-bottom: 10px;
  box-shadow: ${theme.shadow};
  cursor: pointer;
  background: ${theme.foreground};
  & > div {
    font-size: ${desktop.large};
    margin: 0;
    display: flex;
    align-items: center;
  }
  & > h5 {
    font-size: ${desktop.normalS};
    margin: 0;
  }
`;
