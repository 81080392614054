import { useHistory } from "react-router-dom";

const withAuthentication = (WrappedComponent) => {
  return (props) => {
    const router = useHistory();
    if (typeof window !== "undefined") {
      if (localStorage.token) {
        router.replace("/dashboard");
        return null;
      }

      return <WrappedComponent {...props} />;
    }

    return null;
  };
};

export default withAuthentication;
