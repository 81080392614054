import { useStateLga } from "../../helperFunctions/useStateLga";
import { Form, Row, Col, Radio } from "antd";
import { DropDown } from "../../globalComponents/DropDown";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { updateAddress, updateLocation, updateSponsorType } from "./slice";
import { Button, TextInput } from "../../globalComponents/Index";
import { theme } from "../../globalAsset/theme";
import { notificationAlert } from "../../utils/notificationAlert";

export const UpdateLocationComponent = (props) => {
  // const Form.Item = Form.Item;
  const dispatcher = useDispatch();
  const onFinish = async (values) => {
    // console.log("success:", values);
    // props.onClick(values);
    try {
      const response = await dispatcher(updateLocation(values));
      if (response) {
        notificationAlert("success", "updated", "Sponsor Type Updated");
        props.onClick();
      }
    } catch (e) {
      console.log("error from component", e);
      notificationAlert("error", "Error", e.message);
    }
  };
  const onFinishFailed = (errorInfo) => {
    // console.log(errorInfo);
  };
  const [states, lgas, setStateLgas] = useStateLga();
  return (
    <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
      <Form.Item
        label="State"
        name="state"
        rules={[
          {
            required: true,
            message: "Please select a state",
          },
        ]}
      >
        <DropDown
          values={states}
          onChange={(value) => setStateLgas(value)}
        />
      </Form.Item>
      <Form.Item
        label="L.G.A"
        name="lga"
        rules={[
          {
            required: true,
            message: "Please select a L.G.A",
          },
        ]}
      >
        <DropDown values={lgas} />
      </Form.Item>
      <Row justify="center">
        <Col xs={10} offset={1}>
          <Button onClick={props.backFunction} text={props.backText} />
        </Col>
        <Col xs={10} offset={1}>
          <Button htmlType="submit" text={props.buttonText} />
        </Col>
      </Row>
    </Form>
  );
};

export const UpdateSponsorTypeComponent = (props) => {
  const dispatcher = useDispatch();
  const onFinish = async (values) => {
    console.log("success:", values);
    try {
      const response = await dispatcher(updateSponsorType(values.sponsortype));
      if (response) {
        notificationAlert("success", "updated", "Sponsor Type Updated");
        props.onClick();
      }
    } catch (e) {
      console.log("error from component", e);
      notificationAlert("error", "Error", e.message);
    }
    // props.onClick();
  };
  const onFinishFailed = (errorInfo) => {
    // console.log(errorInfo);
  };
  return (
    <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
      <Form.Item
        name="sponsortype"
        rules={[
          {
            required: true,
            message: "Please select a sponsor-type",
          },
        ]}
      >
        <Radio.Group>
          <RadioButton text="Business Organization" />
          <RadioButton text="Academic Researcher" />
          <RadioButton text="Government/Non-governmental Org." />
        </Radio.Group>
      </Form.Item>
      <Button htmlType="submit" text={props.buttonText} />
    </Form>
  );
};

export const UpdateAddressComponent = (props) => {
  const dispatcher = useDispatch();
  const onFinish = async (values) => {
    // console.log("success:", values);
    // props.onClick(values);
    try {
      const response = await dispatcher(updateAddress(values.address));
      if (response) {
        notificationAlert("success", "updated", "Sponsor Type Updated");
        props.onClick();
      }
    } catch (e) {
      console.log("error from component", e);
      notificationAlert("error", "Error", e.message);
    }
  };
  const onFinishFailed = (errorInfo) => {
    // console.log(errorInfo);
  };
  return (
    <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
      <Form.Item
        name="address"
        rules={[
          {
            required: true,
            message: "Please enter a address",
          },
        ]}
      >
        <TextInput />
      </Form.Item>
      <Row justify="center">
        <Col xs={10} offset={1}>
          <Button onClick={props.backFunction} text={props.backText} />
        </Col>
        <Col xs={10} offset={1}>
          <Button htmlType="submit" text={props.buttonText} />
        </Col>
      </Row>
    </Form>
  );
};

const RadioButton = (props) => {
  const radioStyle = {
    textAlign: "left",
    display: "block",

    // height: "30px",
    // lineHeight: "30px",
  };
  return (
    <StyledRadio style={radioStyle} value={props.text}>
      {props.text}
    </StyledRadio>
  );
};
const StyledRadio = styled(Radio)`
  color: ${theme.darkgreen};
`;
