import React, { useState } from "react";
import { BigNav } from "../../../globalComponents/Nav";
import {
  Back,
  ComponentContainer,
} from "../../../globalComponents/globalStyle";
import { Checkbox, Button as AntButton, Space } from "antd";
import { Steps, Popover } from "antd";
import { useHistory } from "react-router-dom";
import { useStateLga } from "../../../helperFunctions/useStateLga";
import { Form, Row, Col, Radio } from "antd";
import { Button } from "../../../globalComponents/Index";
import { device } from "../../../globalAsset/mediaQueries";
import { theme } from "../../../globalAsset/theme";
import styled from "styled-components";
import { desktop } from "../../../globalAsset/fontSize";
import { routes } from "../../../routes";
import { SideEffect } from "../../../Services";
import { selectGlobalData } from "../../Dashboard/slice";
import { useSelector } from "react-redux";
import { notificationAlert } from "../../../utils/notificationAlert";

export const Gender = (props) => {
  const { activeSurveyId } = useSelector(selectGlobalData);
  const [selected, setSelect] = useState(false);

  const handleChange = (e) => {
    setSelect(e.target.checked);
    if (!e.target.checked) {
    }
  };

  const onFinish = async (values) => {
    try {
      var value = null;
      if (values) {
        if (values.gender || values.genderS) {
          if (values.genderS === true) {
            value = { gender: "All Gender" };
          } else if (values.gender) {
            value = { gender: values.gender };
          }
        }
        console.log(value);
      }

      if (value === null || value === undefined) {
        alert("KIndly select a value");
        return;
      }
      value.id = localStorage.surveyId;

      await SideEffect.addSurveyProperties({
        route: routes.rAddSurveyGender,
        data: value,
      });

      notificationAlert(
        "success",
        "Gender added",
        "Gender properties has been added"
      );
      props.next();
    } catch (error) {
      if (error.response) {
        notificationAlert(
          "error",
          "Error Occurred",
          error.response.data.message
        );
      }
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("the error is", errorInfo);
  };

  return (
    <Form
      style={{ paddingTop: "16px" }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item name="genderS" valuePropName="checked">
        <Checkbox
          required={selected && true}
          checked={selected}
          onChange={(e) => {
            handleChange(e);
            console.log(e);
          }}
        >
          <Title> All Gender</Title>
        </Checkbox>
      </Form.Item>

      {/* <br /> */}
      {!selected && (
        <Form.Item name="gender">
          <Radio.Group>
            <RadioButton text="Male" />
            <RadioButton text="Female" />
          </Radio.Group>
        </Form.Item>
      )}

      <Button htmlType="submit" text={props.buttonText || "Next"} />
    </Form>
  );
};

export const Settlement = (props) => {
  const [selected, handleSelect] = useState(false);
  const { activeSurveyId } = useSelector(selectGlobalData);

  const onFinish = async (values) => {
    try {
      var value = null;
      if (values) {
        if (values.settlement || values.settlementS) {
          if (values.settlementS === true) {
            value = { settlement: "All Settlement Types" };
          } else if (values.settlement) {
            value = { settlement: values.settlement };
          }
        }
        console.log(value);
      }
      if (value === null && value === undefined) {
        alert("Kindly select a value");
        return;
      }

      value.id = localStorage.surveyId;
      const response = await SideEffect.addSurveyProperties({
        route: routes.rAddSurveySettlement,
        data: value,
      });
      console.log("SETTLEMENT", response);
      notificationAlert(
        "success",
        "Settlement added",
        "Settlement properties has been added"
      );
      props.next();
    } catch (error) {
      if (error.response) {
        notificationAlert(
          "error",
          "Error Occurred",
          error.response.data.message
        );
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    // console.log(errorInfo);
  };
  return (
    <Form
      style={{ paddingTop: "16px" }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item name="settlementS" valuePropName="checked">
        <Checkbox
          checked={selected}
          value={!selected && "All Settlement Types"}
          onChange={(e) => {
            handleSelect(e.target.checked);
            console.log(e);
          }}
        >
          <Title> All Settlement Types</Title>
        </Checkbox>
      </Form.Item>
      {!selected && (
        <Form.Item name="settlement">
          <Radio.Group>
            <RadioButton text="Rural" />
            <RadioButton text="Urban" />
          </Radio.Group>
        </Form.Item>
      )}

      <PrevNextButton
        hideBack={props.hideBack}
        back={props.back}
        buttonText={props.buttonText}
      />
    </Form>
  );
};

export const State = (props) => {
  const [states] = useStateLga();
  const [selected, handleSelect] = useState(false);
  const { activeSurveyId } = useSelector(selectGlobalData);

  const onFinish = async (values) => {
    try {
      let value = null;
      if (values) {
        if (values.state || values.stateS) {
          if (values.stateS === true) {
            value = { state: "All States" };
          } else if (values.state) {
            value = { state: values.state };
            // console.log("third value", value.state[0]);
          }
        }
      }

      if (value === null || !value.state || value.state.length < 1) {
        alert("KIndly select a value");
        return;
      }

      if (Array.isArray(value.state)) {
        value.state = value.state.reduce((prevValue, currValue) => {
          return `${prevValue}${prevValue ? "," : ""}${currValue
            .split(",")
            .join("<!&&!>")}`;
        }, "");
      }

      //value.state = value.state.join(",");

      value.id = localStorage.surveyId;

      const response = await SideEffect.addSurveyProperties({
        route: routes.rAddSurveyState,
        data: value,
      });
      notificationAlert(
        "success",
        "State added",
        "State properties has been added"
      );
      props.next();
    } catch (error) {
      if (error.response) {
        notificationAlert(
          "error",
          "Error Occurred",
          error.response.data.message
        );
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo);
  };
  return (
    <Form
      style={{ paddingTop: "16px" }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item name="stateS" valuePropName="checked">
        <Checkbox
          checked={selected}
          // value={!selected && "All states"}
          onChange={(e) => {
            handleSelect(e.target.checked);
            console.log(e);
          }}
        >
          <Title> All States</Title>
        </Checkbox>
      </Form.Item>
      <Form.Item name="state">
        {!selected && (
          <Checkbox.Group>
            {states.map((val) => (
              <Row>
                <Col md={{ span: 24 }}>
                  <Checkbox value={val.text}>{val.text}</Checkbox>
                </Col>
              </Row>
            ))}
          </Checkbox.Group>
        )}
      </Form.Item>

      <PrevNextButton
        hideBack={props.hideBack}
        back={props.back}
        buttonText={props.buttonText}
      />
    </Form>
  );
};

export const MonthlyIncome = (props) => {
  const [selected, handleSelect] = useState(false);
  const { activeSurveyId } = useSelector(selectGlobalData);

  const onFinish = async (values) => {
    try {
      var value = null;
      if (values) {
        if (values.monthly || values.monthlyS) {
          if (values.monthlyS === true) {
            value = { montly_income: "All Monthly Income Level" };
          } else if (values.monthly) {
            value = { montly_income: values.monthly };
            // console.log("third value", value.state[0]);
          }
        }
        console.log(value);
      }
      if (value.montly_income[0] === undefined) {
        console.log("second value", value);
        alert("KIndly select a value");
        return;
      }

      if (Array.isArray(value.montly_income)) {
        value.montly_income = value.montly_income.reduce(
          (prevValue, currValue) => {
            return `${prevValue}${prevValue ? "," : ""}${currValue
              .split(",")
              .join("<!&&!>")}`;
          },
          ""
        );
      }
      value.id = localStorage.surveyId;

      console.log(value);
      const response = await SideEffect.addSurveyProperties({
        route: routes.rAddSurveyMonthlyIncome,
        data: value,
      });
      console.log("MONTHLY", response);
      notificationAlert(
        "success",
        "Monthly income added",
        "Monthly income property has been added"
      );
      props.next();

      console.log("second value", value);
    } catch (error) {
      if (error.response) {
        notificationAlert(
          "error",
          "Error Occurred",
          error.response.data.message
        );
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    // console.log(errorInfo);
  };
  return (
    <Form
      style={{ paddingTop: "16px" }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item name="monthlyS" valuePropName="checked">
        <Checkbox
          checked={selected}
          value={!selected && "All Monthly Income Level"}
          onChange={(e) => {
            handleSelect(e.target.checked);
            console.log(e);
          }}
        >
          <Title> All Monthly Income Level</Title>
        </Checkbox>
      </Form.Item>

      {!selected && (
        <Form.Item name="monthly">
          <Checkbox.Group>
            <Row>
              <Col md={{ span: 24 }}>
                <Checkbox
                  value={"Not currently working, someone else pays my bills"}
                >
                  Not currently working, someone else pays my bills
                </Checkbox>
              </Col>
              <Col md={{ span: 24 }}>
                <Checkbox value={"Earn less than N30,000 monthly"}>
                  Earn less than N30,000 monthly
                </Checkbox>
              </Col>
              <Col md={{ span: 24 }}>
                <Checkbox value={"Earn between N30,001 and N100,000 monthly"}>
                  Earn between N30,001 and N100,000 monthly
                </Checkbox>
              </Col>
              <Col md={{ span: 24 }}>
                <Checkbox value={"Earn above N100,000 monthly"}>
                  Earn above N100,000 monthly
                </Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
      )}
      <PrevNextButton
        hideBack={props.hideBack}
        back={props.back}
        buttonText={props.buttonText}
      />
    </Form>
  );
};

export const AgeGroup = (props) => {
  const otherOptions = [
    "18 - 24 years",
    "25 - 34 years",
    "35 - 44 years",
    "45 - 54 years",
    "55 - 64 years",
    "Above 65 years",
  ];
  const [selected, handleSelect] = useState(false);
  const { activeSurveyId } = useSelector(selectGlobalData);

  const onFinish = async (values) => {
    try {
      var value = null;
      if (values) {
        if (values.age || values.ageS) {
          if (values.ageS === true) {
            value = { age_group: "All Age Group" };
          } else if (values.age) {
            value = { age_group: values.age };
            // console.log("third value", value.state[0]);
          }
        }
        console.log(value);
      }
      if (value.age_group[0] === undefined) {
        alert("KIndly select a value");
        return;
        //props.next();
      }

      if (Array.isArray(value.age_group)) {
        value.age_group = value.age_group.reduce((prevValue, currValue) => {
          return `${prevValue}${prevValue ? "," : ""}${currValue
            .split(",")
            .join("<!&&!>")}`;
        }, "");
      }

      value.id = localStorage.surveyId;

      console.log(value);
      const response = await SideEffect.addSurveyProperties({
        route: routes.rAddSurveyAgeGroup,
        data: value,
      });
      console.log("AGE", response);
      notificationAlert(
        "success",
        "Settlement added",
        "Settlement properties has been added"
      );
      props.next();

      console.log("second value", value);
    } catch (error) {
      console.log(error);
    }
  };
  const onFinishFailed = (errorInfo) => {
    // console.log(errorInfo);
  };
  return (
    <Form
      style={{ paddingTop: "16px" }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item name="ageS" valuePropName="checked">
        <Checkbox
          checked={selected}
          value={!selected && "All Age Group"}
          onChange={(e) => {
            handleSelect(e.target.checked);
            console.log(e);
          }}
        >
          <Title> All Age Group</Title>
        </Checkbox>
      </Form.Item>{" "}
      <Form.Item name="age">
        {!selected && (
          <Checkbox.Group>
            {otherOptions.map((val) => (
              <Row>
                <Col md={{ span: 24 }}>
                  <Checkbox value={val}>{val}</Checkbox>
                </Col>
              </Row>
            ))}
          </Checkbox.Group>
        )}
      </Form.Item>
      <PrevNextButton
        hideBack={props.hideBack}
        back={props.back}
        buttonText={props.buttonText}
      />
    </Form>
  );
};

export const Education = (props) => {
  const history = useHistory();
  const otherOptions = [
    "Pre-school",
    "Primary incomplete",
    "Primary complete",
    "Secondary incomplete",
    "Secondary complete",
    "University/Polytechnic Undergraduate",
    "University/Polytechnic OND complete",
    "University/Polytechnic HND complete",
    "Post-university incomplete",
    "Post-university complete",
    "Non-formal education (e.g. Arabic/Quranic education)",
    "No education",
  ];
  const [selected, handleSelect] = useState(false);
  const { activeSurveyId } = useSelector(selectGlobalData);

  const onFinish = async (values) => {
    try {
      var value = null;
      if (values) {
        if (values.education || values.educationS) {
          if (values.educationS === true) {
            value = { education: "All Education Level" };
          } else if (values.education) {
            value = { education: values.education };
            // console.log("third value", value.state[0]);
          }
        }
        console.log(value);
      }
      if (value.education[0] === undefined) {
        alert("Kindly select a value");
        //props.next();
      }

      if (Array.isArray(value.education)) {
        value.education = value.education.reduce((prevValue, currValue) => {
          return `${prevValue}${prevValue ? "," : ""}${currValue
            .split(",")
            .join("<!&&!>")}`;
        }, "");
      }

      value.id = localStorage.surveyId;

      const response = await SideEffect.addSurveyProperties({
        route: routes.rAddSurveyEducation,
        data: value,
      });

      notificationAlert(
        "success",
        "Settlement added",
        "Settlement properties has been added"
      );
      props.next();
    } catch (error) {}
  };
  const onFinishFailed = (errorInfo) => {
    // console.log(errorInfo);
  };
  return (
    <Form
      style={{ paddingTop: "16px" }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item name="educationS" valuePropName="checked" ru>
        <Checkbox
          checked={selected}
          value={!selected && "All Education Level"}
          onChange={(e) => {
            handleSelect(e.target.checked);
            console.log(e);
          }}
        >
          <Title> All Education Level</Title>
        </Checkbox>
      </Form.Item>
      <Form.Item name="education">
        {!selected && (
          <Checkbox.Group>
            {otherOptions.map((val) => (
              <Row>
                <Col md={{ span: 24 }}>
                  <Checkbox value={val}>{val}</Checkbox>
                </Col>
              </Row>
            ))}
          </Checkbox.Group>
        )}
      </Form.Item>
      <PrevNextButton
        hideBack={props.hideBack}
        back={props.back}
        buttonText={props.buttonText}
      />
    </Form>
  );
};

const RadioButton = ({ text }) => {
  const radioStyle = {
    textAlign: "left",
    display: "block",

    // height: "30px",
    // lineHeight: "30px",
  };
  return (
    <StyledRadio style={radioStyle} value={text}>
      {text}
    </StyledRadio>
  );
};

export const PrevNextButton = (props) => {
  return (
    <Space direction="horizontal">
      {!props.hideBack && (
        <BackDiv size="large" onClick={props.back}>
          Back
        </BackDiv>
      )}

      <Button htmlType="submit" text={props.buttonText || "Next"} />
    </Space>
  );
};

const StyledRadio = styled(Radio)`
  color: ${theme.darkgreen};
`;

const Title = styled.h5`
  font-size: ${desktop.medium};
  font-weight: 600;
`;

const BackDiv = styled(AntButton)`
  border-radius: 5px;
  cursor: pointer;
`;
