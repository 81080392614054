import styled from "styled-components";
import { desktop } from "../../globalAsset/fontSize";
import { theme } from "../../globalAsset/theme";

export const Container = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Button = styled.button`
  padding: 5px 10px;
  font-size: ${desktop.normal};
  background: ${theme.secondaryDark};
  border-radius: 5px;
  color: white;
  border: none;
  outline: none;
  box-shadow: ${theme.shadow};
  cursor: pointer;
  :hover {
    box-shadow: none;
  }
`;

export const Info = styled.h5`
  font-style: italic;
  font-size: ${desktop.normalS};
  color: ${theme.grey};
  text-align: center;
`;
