import React from 'react';
import { SingleQuestionScaffold } from '../elements/singleQuestionScaffold';
import { Matrix } from '../atom/SingleQuestion';

export const MatrixQuestion = (props) => {
    return (
        <SingleQuestionScaffold questionId={props.questionObj.questionId} questionNumber={props.questionNumber} question={props.questionObj.question}>
            <Matrix onChange={props.onChange} options={props.questionObj.options} categories={props.questionObj.categories}/>
        </SingleQuestionScaffold>
    )
}