import styled from "styled-components";
import { Statistic } from "antd";
import { theme } from "../../../globalAsset/theme";
import { desktop } from "../../../globalAsset/fontSize";
export const Value = styled(Statistic)`
  margin-left: 15px;
  & .ant-statistic-content {
    padding: 0;
    margin: 0 !important;

    font-weight: 700 !important;
    color: ${theme.secondaryDark}!important;

    & .ant-statistic-content-value-int {
      font-weight: 700 !important;
      font-size: ${desktop.large};
    }
  }
  & .ant-statistic-content-prefix {
    font-size: ${desktop.medium};

    padding: 0;
    margin: 0 !important;

    font-weight: 700 !important;
    color: ${theme.secondaryDark}!important;
  }
`;

export const Review = styled.div`
  margin: 0 0 1vw 0;
  display: flex;
  align-items: center;
  h4 {
    font-size: ${desktop.medium};
    margin: 0;
    & > span {
      margin: 0 0 0 0.5vw;
      font-weight: 600;
    }
  }
`;
