import styled from "styled-components";
import { device } from "../../globalAsset/mediaQueries";
import { desktop, mobile } from "../../globalAsset/fontSize";
import { theme } from "../../globalAsset/theme";
export const CounterContainer = styled.div`
  border: 1px solid red;
  @media ${device.laptop} {
    display: block;
    background: none;

    margin: 2vw 0 5vw 0;
    height: 50px;
  }
`;
export const RedeemableIconContainer = styled.div`
    height: 40px;
    width: 40px;
    color: white;
    text-align: center;
    border-radius: 10px;
    padding: 5px 0 0 0;
    text-align: center;
    margin-right: 15px;
    background-image: linear-gradient(
      to right,
      ${theme.lightOrange},
      ${theme.darkorange}
    );
  
`;
export const CardContainer = styled.div`
    display: flex;
    align-items: center;
    background: white;
    justify-content: flex-start;
    padding: 15px;
    box-shadow: 1px 0px 4px 0px rgba(50, 50, 71, 0.1);
    border-radius: 5px;
    width: 100%;
    & > h3 {
      color: black;
      font-size: 1vw;
      margin: 0;
    }
    & > h5 {
      color: black;
      font-size: 1.5vw;
      margin: 0;
    }
  @media ${device.tablet} {
    width: fit-content;
  }
`;
export const Button = styled.button`
  background: linear-gradient(
    to right,
    ${theme.lightOrange},
    ${theme.darkorange}
  );
  text-align: center;
  font-weight: 600;
  color: white;
  border: none;
  outline: none;
  border-radius: 3px;
  margin: 20px auto;

  padding: 5px 10px;
  cursor: pointer;
  box-shadow: ${theme.shadow};
`;

export const SurveyCardComponent = styled.div`
  border: none;
  border-radius: 5px;
  outline: none;
  background: white;
  ${"" /* box-shadow: 0px 0px 10px ${activeShadow2}; */}
  box-shadow:0px 3px 8px -1px rgba(50, 50, 71, 0.08);
  & > p {
    margin-bottom: 0;
    font-size: ${desktop.small};
    text-align: start;
   // padding: 10px 0 0 15px;
  }
  & > h3 {
    font-size: ${desktop.normal};
    font-weight: bold;
    text-align: center;
    margin: 0 0;
  }
  & > h5 {
    font-size: ${desktop.small};
    font-style: italic;
    text-align: center;
    margin: 0 0;
  }
`;
export const Hr = styled.hr`
  background-color: ${theme.subtleGrey};
  height: 1px;
  outline: none;

  border: none;
`;
export const AnalyticButton = styled.button`
  width: 100%;
  text-align: center;
  padding: 5px 0;
  background: black;
  color: white;
  border: none;
  outline: none;
  border-radius: 5px;
  margin: 10px 0 0 0;
  cursor: pointer;
`;
export const Active = styled.div`
  background: ${(props) =>
    props.active ? theme.lightgreen : theme.lightOrange};
  border-radius: 10px;
  padding: 1px 10px;
  margin-left: auto;
  width: fit-content;
  height: fit-content;
  color: #fff;

  & > p {
    margin-bottom: 0;
  }
`;
