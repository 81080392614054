import React, { useState } from "react";
import { BigNav } from "../../../globalComponents/Nav";
import {
  Back,
  ComponentContainer,
  InnerContainer,
} from "../../../globalComponents/globalStyle";
import {
  AgeGroup,
  Education,
  Gender,
  Settlement,
  MonthlyIncome,
  State,
} from "./stepContent";
import { Checkbox } from "antd";
import { Steps, Popover } from "antd";
import { useHistory } from "react-router-dom";
import { useStateLga } from "../../../helperFunctions/useStateLga";
import { Form, Row, Col, Radio } from "antd";
import { Button } from "../../../globalComponents/Index";
import { device } from "../../../globalAsset/mediaQueries";
import { theme } from "../../../globalAsset/theme";
import styled from "styled-components";
import { desktop } from "../../../globalAsset/fontSize";
import useScreenSize from "../../../utils/useSmallScreen";
import { screenSizes } from "../../../globalAsset/mediaQueries";

const SampleBalanceProp = () => {
  const [selected, handleSelect] = useState(false);
  const { isWithinScreenSize } = useScreenSize(screenSizes.tablet);
  const [states] = useStateLga();
  const [current, setCurrent] = useState(0);
  const history = useHistory();
  const next = () => {
    setCurrent(current + 1);
    handleSelect(false);
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const stepContent = [
    { id: 1, content: <Gender next={next} /> },
    { id: 2, content: <State back={prev} next={next} /> },
    { id: 3, content: <Settlement back={prev} next={next} /> },
    { id: 4, content: <MonthlyIncome back={prev} next={next} /> },
    { id: 5, content: <AgeGroup back={prev} next={next} /> },
    {
      id: 6,
      content: (
        <Education
          back={prev}
          next={() => history.push("/dashboard/survey-properties2")}
        />
      ),
    },
  ];

  const customDot = (dot, { status, index }) => (
    <Popover
      content={
        <span>
          {/* step {index} status: {status} */}
          STEP {index + 1}
        </span>
      }
    >
      {dot}
    </Popover>
  );
  const { Step } = Steps;
  const StepsVariable = (
    <div>
      <Steps
        size="small"
        direction="horizontal"
        style={{
          paddingTop: "10px",
          marginLeft: "-3.250vw",
          // boxSizing: "border-box",
        }}
        progressDot={customDot}
        responsive
        current={current}
      >
        {stepContent.map((item, id) => (
          <Step
            size="small"
            key={id}
            // title={item.question}
          />
        ))}
      </Steps>
    </div>
  );

  return (
    <>
      <BigNav title="Survey Properties (1 of 2)" />
      <ComponentContainer>
        <InnerContainer>
          {!isWithinScreenSize && StepsVariable}
          {stepContent[current].content}
        </InnerContainer>
      </ComponentContainer>
    </>
  );
};
export default SampleBalanceProp;
const StepContainer = styled.div`
  padding: 10px 0px;
  @media ${device.laptop} {
    width: 25vw;
    margin: 0 20px;
    text-align: center;
    h4 {
      font-size: ${desktop.medium};
      margin: 1vw 0 2vw 0;
    }
  }
`;

const RadioButton = (props) => {
  const radioStyle = {
    textAlign: "left",
    display: "block",

    // height: "30px",
    // lineHeight: "30px",
  };
  return (
    <StyledRadio style={radioStyle} value={props.text}>
      {props.text}
    </StyledRadio>
  );
};
const StyledRadio = styled(Radio)`
  color: ${theme.darkgreen};
`;

const Title = styled.h5`
  font-size: ${desktop.medium};
  font-weight: 600;
`;

const BackDiv = styled.div`
  background: ${theme.secondaryDark};
  height: 2.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  font-weight: 500;
  width: fit-content;
  padding: 0vw 0.7vw;
  border-radius: 3px;
`;
