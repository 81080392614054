export const registerSponsorRoute = "/api/register-business";
export const resendVerificationRoute = "/api/resend-email-verification";
export const sponsorLoginRoute = "/api-aut-login";
export const forgotPasswordRoute = "/api/forgot-password";
export const fetchUserInfoRoute = "/api/user";
export const addSponsorType = "/api/add-sponsor-type";
export const addAddress = "/api/add-address";
export const addLocation = "/api/add-location";
export const addProfilePic = "/api/add-profilepic";
export const fetchAssaignedSurvey = "/api/my-surveys?page:";

export const routes = {
  rUserLogin: "/api-aut-login",
  rFetchUserInfo: "/api/user",
  rAddSurvey: "/api/add-survey",
  rFetchSurvey: "/api/view-my-surveys?page=",
  rRegisterSponsor: "/api/register-business",
  rRegisterParticipant: "/api/register-user",
  rAddLocation: "/api/add-location",
  rAddSettlement: "/api/add-settlemet",
  rAddMonthlyIncome: "/api/add-montly_income",
  rAddGender: "/api/add-gender",
  rAddMarritalStatus: "/api/add-marrital_status",
  rAddHousehold: "/api/add-household",
  rAddDOB: "/api/add-date_of_birth",
  rAddEducation: "/api/add-education",
  rAddMobileOwnership: "/api/add-mobile_ownership",
  rAddIncomeSource: "/api/add_income",
  rAddSurveyEducation: "/api/properties/survey-education",
  rAddSurveyMonthlyIncome: "/api/properties/survey-montly_income",
  rAddSurveyAgeGroup: "/api/properties/survey-age_group",
  rAddSurveySettlement: "/api/properties/survey_settlement",
  rAddSurveyState: "/api/properties/survey-state",
  rAddSurveyGender: "/api/properties/survey-gender",
  rAddSponsorType: "/api/add-sponsor-type",
  rAddOrgName: "/api/add-org-name",
  rAddAddress: "/api/add-address",
  rAddPhone: "/api/add-phone-number",
  rSaveSingleQuestionObject: "/api/add-questions",
  rFetchSingleSurvey: "/api/view-surveys?survey_id=",
  rSampleNeeded: "/api/properties/sample-needed",
  rQuestionRange: "/api/properties/question-range",
  rFetchSurveyProperties: "/api/properties/survey-properties?survey_id=",
  rFundSurvey: "/api/fund-survey",
  rFetchAssaignedSurvey: "/api/my-surveys?page=1",
  rDeleteSingleSurvey: "/api/delete-survey?survey_id=",
  rDeleteSingleQuestion: "/api/delete-question?question_id=",
  rSubmitResponse: "/api/submit-responds",
  rSubmitScreeningResponse: "/api/submit-screening_question",
  rFetchPurchasedSurvey: "/api/sponsor_survey_active?page=",
  rFetchTemplates: "/api/view-template?page=",
  rEditSurveyTitle: "/api/properties/survey-title",
  rforgotPassword: "/api/forgot-password",
  rAddProfilePic: "/api/add-profilepic",
  rResendVerificationLink: "/api/resend-email-verification",
  rSubmitComplaint: "/api/submit-complaint",
  rAddAccountDetails: "/api/add-account",
  rWithdrawalRequest: "/api/request-withdraw",
  rFetchSurveyAnalysis: "/api/view-analysis",
  rSaveCoordinates: "/api/update-location",
  rParticipantSurveyHistory: "/api/user_survey_history?page=",
  rConfirmSurvey: "/api/submit-survey",
  rAddAgeGroup: "/api/add-age_group",
  
};
