export const mobile = {
  large: "32px",
  medium: "18px",
  small: "12px",
  normal: "16px",
};

export const desktop = {
  normalS: "14px",
  large: "24px",
  medium: "18px",
  normal: "16px",
  small: "11px",
};
