import { configureStore } from "@reduxjs/toolkit";
import surveyModalReducer from "../globalComponents/slices/surveyModalSlice";
import globalData from "../Components/Dashboard/slice";
import updateReducer from "../Components/UpdateProfile/slice";
import surveyReducer from '../Components/CreateSurveyFlow/slice';
import archiveSurveyReducer from '../Components/SurveyArchive/slice';
import surveyAnalysisReducer from '../Components/Analysis/slice';
import surveyTemplateReducer from '../Components/TemplateSurvey/slice'

export default configureStore({
  reducer: {
    surveyModalReducer,
    globalData,
    updateReducer,
    surveyReducer,
    archiveSurveyReducer,
    surveyAnalysisReducer,
    surveyTemplateReducer
  },
});
