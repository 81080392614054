import { Drawer } from 'antd';
import React from 'react';


const MobileSideMenu = (props) => {
 
  return (
    <>
      <Drawer title={props.title} placement="left" onClose={props.onClose} visible={props.visible}>
       {props.children}
      </Drawer>
    </>
  );
};
export default MobileSideMenu;