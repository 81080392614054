import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import { device } from "../../globalAsset/mediaQueries";
import { ComponentContainer } from "../../globalComponents/Index";
import { BigNav } from "../../globalComponents/Nav";
import { Back, BackContainer } from "../../globalComponents/globalStyle";
import { Typography, Table, Select, Row, Col } from "antd";
import { FiRefreshCcw } from "react-icons/fi";
import { theme } from "../../globalAsset/theme";
import { LineChartt } from "./Chart";
import { useParams } from "react-router-dom";
import {
  fetchSurveyAnalysis,
  selectSurveyAnalysis,
  fetchSingleSurvey,
} from "./slice";
import { AiOutlineDownload } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import encryptNumberString from "../../helperFunctions/encryptNumberString";

const { Option } = Select;

const columns = [
  {
    title: "Options",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Number of Response",
    dataIndex: "reponds",
    key: "reponds",
  },
  {
    title: "% of Response",
    dataIndex: "percent",
    key: "percent",
  },
];

const Analysis = () => {
  const { Option } = Select;
  const [activeQuestion, setActiveQuestion] = useState([]);
  const [questionSelectList, setSelectList] = useState([]);
  const [isChart, setIsChart] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const { surveyAnalysis, singleSurvey } = useSelector(selectSurveyAnalysis);

  const [activeQuestionNumber, setActiveQuestionNumber] = useState(1);
  const surveyAnalysis1 = useSelector((state) => state.surveyAnalysis);
  const history = useHistory();
  useEffect(() => {
    dispatch(fetchSurveyAnalysis({ data: { survey_id: id } }));
    dispatch(fetchSingleSurvey({ surveyId: id }));
  }, [id]);

  useEffect(() => {
    const analysis = [...surveyAnalysis][0];

    setActiveQuestion(analysis?.survey_quetions[0]);

    let newList = [...surveyAnalysis].map((item, index) => {
      return index + 1;
    });

    setSelectList(newList);
  }, [surveyAnalysis]);

  const onQuestionChange = (number) => {
    const analysis = [...surveyAnalysis][number - 1];

    setActiveQuestionNumber(number);
    setActiveQuestion(analysis?.survey_quetions[0]);
  };

  /* $kHostname/api/export-survey-question?survey_id=$surveyId */
  return (
    <>
      <BigNav title="Survey Analysis" />
      <ComponentContainer>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Back />
          <BackContainer
            onClick={() => {
              localStorage.surveyId = id;
              history.push("/dashboard/view-survey");
            }}
          >
            <button type="button" className="btn btn-outline-success fw-bolder">
              View Survey
            </button>
          </BackContainer>
        </div>

        <StyledAnalysisComponent>
          {singleSurvey.respondent_type === "unassigned" && (
            <>
              <p>
                Please copy the link below and share with your respondents.
                Thank you.
              </p>
              <Typography.Title
                level={4}
                copyable
                strong
                style={{ marginBottom: 22 }}
              >
                {`https://respondents.getsurveyplus.com/external-survey-link/${encryptNumberString(id)}`}
              </Typography.Title>
            </>
          )}
          <Row gutter={[16, 16]}>
            <Col xs={24} md={8}>
              <QuestionNumberList
                data={questionSelectList}
                defaultValue={activeQuestionNumber}
                onChange={onQuestionChange}
              />
            </Col>
            <Col xs={24} md={8}>
              <StyledSwitch
                onClick={() => {
                  setIsChart((prevState) => !prevState);
                }}
              >
                <StyledFiRefreshCcw />
                <p>Switch to {isChart ? "Table" : "Chart"}</p>
              </StyledSwitch>
            </Col>
            <Col xs={24} md={8}>
              <StyledSwitch
                onClick={() => {
                  window.location.href = `https://api.getsurveyplus.com/api/export-survey-question?survey_id=${id}`;
                }}
              >
                <StyledAiOutlineDownload />
                <p>Download Analysis</p>
              </StyledSwitch>
            </Col>
          </Row>

          <StyledQuestionDiv>
            <h3>{activeQuestion?.question_title}</h3>
          </StyledQuestionDiv>

          {activeQuestion?.question_type !== "single_input" && (
            <GroupQuestion
              data={activeQuestion?.responses}
              showChart={isChart}
              dataSource={activeQuestion?.responses}
            />
          )}
          {activeQuestion?.question_type === "single_input" && (
            <ol>
              {activeQuestion.responses.map((item, index) => {
                return (
                  <li key={item}>
                    {item.split("['").join("").split("']").join("")}
                  </li>
                );
              })}
            </ol>
          )}
        </StyledAnalysisComponent>
      </ComponentContainer>
    </>
  );
};
export default Analysis;

const QuestionNumberList = (props) => {
  const options = props.data.map((item, index) => {
    return (
      <Option key={index} value={item}>
        {item}
      </Option>
    );
  });
  return (
    <StyledQuestionNumberList>
      <p>Select Question</p>
      <StyledSelect
        defaultValue={props.defaultValue}
        bordered={false}
        onChange={props.onChange}
      >
        {options}
      </StyledSelect>
    </StyledQuestionNumberList>
  );
};

const GroupQuestion = (props) => {
  const [chartWidth, setChartWidth] = useState(window.innerWidth - 200);

  const questionRef = useRef();
  useEffect(() => {
    setChartWidth(questionRef?.current.getBoundingClientRect().width / 2);
  }, []);
  return (
    <StyledGroupQuestion ref={questionRef}>
      {props.showCategory && (
        <StyledCategory>
          <h3>What is your favourite color</h3>
        </StyledCategory>
      )}
      {props.showChart ? (
        <LineChartt width={chartWidth} data={props.data} />
      ) : (
        <div style={{ width: "100%", overflow: "auto" }}>
          <StyledTable
            columns={columns}
            dataSource={props.dataSource}
            pagination={false}
          />
        </div>
      )}
    </StyledGroupQuestion>
  );
};

const StyledTable = styled(Table)`
  width: 100%;
  @media ${device.laptop} {
    width: 70%;
    margin: 0 auto;
  }
`;

const StyledCategory = styled.div`
  text-align: center;
  background-color: #2aba14;
  width: 70%;
  margin: 0 auto;
  padding: 10px;

  & h3 {
    font-size: 18px;
    color: #fff;
    margin: 0px;
  }

  @media ${device.laptop} {
    & h3 {
      font-size: 1.2vw;
    }
  }
`;

const StyledGroupQuestion = styled.div`
  display: grid;
  place-items: center;
  /*  @media (max-width: 567px) {
    width: 150vw;
    margin-left: -130px;
  } */
`;

const StyledQuestionDiv = styled.div`
  text-align: center;

  width: 70%;
  margin: 0 auto;
  padding: 10px;

  & h3 {
    font-size: 10px;
    margin: 0px;
  }

  @media ${device.laptop} {
    & h3 {
      font-size: 1.5vw;
    }
  }
`;

const StyledFiRefreshCcw = styled(FiRefreshCcw)`
  font-size: 20px;
  margin-right: 10px;
  color: #61d926;

  @media ${device.laptop} {
    font-size: 1.5vw;
  }
`;

const StyledAiOutlineDownload = styled(AiOutlineDownload)`
  font-size: 20px;
  margin-right: 10px;
  color: #61d926;

  @media ${device.laptop} {
    font-size: 1.5vw;
  }
`;

const StyledSwitch = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${theme.grey};
  background-color: #fff;
  padding: 5px 20px;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;

  & p {
    margin: 0px;
    font-size: 16px;
  }

  @media ${device.tablet} {
  }
`;

const StyledQuestionNumberList = styled.div`
  background-color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid ${theme.grey};
  background-color: #fff;
  padding: 3px 20px;
  justify-content: space-between;
  border-radius: 5px;
  cursor: pointer;

  & p {
    margin-bottom: 0px;
    margin-right: 15px;
    font-size: 15px;
  }

  @media ${device.tablet} {
    & p {
      font-size: 16px;
    }
  }
`;

const StyledAnalysisComponent = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 20px;

  @media ${device.laptop} {
    width: 92%;
    margin: 20px auto;
    margin-bottom: 50px;
  }
`;
const StyledSelect = styled(Select)`
  width: fit-content;
  font-size: 15px;
  font-weight: bold;

  @media ${device.tablet} {
    font-size: 16px;
  }
`;
const StyledAnalysisActionDiv = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;
