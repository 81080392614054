import { createSlice } from "@reduxjs/toolkit";
import { SideEffect } from "../../Services";

const analysisSlice = createSlice({
  name: "analysisSlice",
  initialState: {
    surveyAnalysis: [],
    singleSurvey: {},
  },

  reducers: {
    addSurveyAnalysis: (state, { payload }) => {
      state.surveyAnalysis = payload;
    },
    addSingleSurvey: (state, { payload }) => {
      state.singleSurvey = payload;
    },
  },
});

export const { addSurveyAnalysis, addSingleSurvey } = analysisSlice.actions;

export const fetchSurveyAnalysis = (payload) => (dispatcher) => {
  return SideEffect.fetchSurveyAnalysis(payload).then(
    (response) => {
  
      dispatcher(addSurveyAnalysis(response.data.data));
      return Promise.resolve(response.data.data);
    },
    (error) => {
      if (error.response) {
        return Promise.reject(error.response.data);
      }
    }
  );
};

export const fetchSingleSurvey = (payload) => async (dispatcher) => {
  const response = await SideEffect.fetchSingleSurvey(payload);
  dispatcher(addSingleSurvey(response.data));
};

export const selectSurveyAnalysis = (state) => state.surveyAnalysisReducer;

export default analysisSlice.reducer;
