import React from 'react';
import styled from 'styled-components';
import { device } from '../../../../globalAsset/mediaQueries';
import { theme } from '../../../../globalAsset/theme';

export const Tabs = (props) => {

    return (
        <StyledTabContainer>
            {props.tabs}
        </StyledTabContainer>
    );
};


export const Tab = (props) => {
    return (
        <StyledTab clicked={props.isActive} onClick={props.onClick}>
            <p>{props.titleText}</p>
        </StyledTab>
    )
}


const StyledTabContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid #dddddd;
`

const StyledTab = styled.div`
    border-bottom: ${props => props.clicked && `2px solid ${theme.lightgreen}`};
    color: ${props => props.clicked? theme.lightgreen : '#92a3b5' };
    margin-right: 20px;
    padding-bottom: 10px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    & p{
        margin-bottom: 0px;
    }
    &:hover{
        color: ${props => props.clicked? theme.lightgreen : '##4A5567' };
        border-bottom: ${props => !props.clicked && '2px solid rgb(177, 192, 201)'};
    }

    @media ${device.laptop}{
        font-size: 1vw;
    }
`