import React, { useEffect } from "react";
import "antd/dist/antd.css";
import Login from "./Components/Login/Index";
import Register from "./Components/Register/Index";
import AppLayout from "./globalComponents/AppLayout";
import Dashboard from "./Components/Dashboard/Index";
import Analysis from "./Components/Analysis/Index";
import UpdateProfile from "./Components/UpdateProfile/Index";
import ServiceTerms from "./Components/ServiceTerms/Index";
import { useDispatch } from "react-redux";
import SurveyProperties from "./Components/CreateSurveyFlow/SampleBalanceProps/index";
import SurveyProperties2 from "./Components/CreateSurveyFlow/SampleBalanceProps/index-two";
import { fetchUserInfo } from "./Components/Dashboard/slice";
import { SurveySummary } from "./Components/CreateSurveyFlow/SurveySummary";
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
import Profile from "./Components/Profile/Index";
import SurveyArchive from "./Components/SurveyArchive/Index";
import Survey from "./Components/Survey/Index";
import { CreateSurvey } from "./Components/CreateSurveyFlow/SampleBalanceProps/CreateSurvey";
import "axios-progress-bar/dist/nprogress.css";
import { loadProgressBar } from "axios-progress-bar";
import { SurveyDashboard } from "./Components/CreateSurveyFlow/surveySystem/SurveyDashboard";
import { ViewSurvey } from "./Components/CreateSurveyFlow/surveySystem/ViewSurvey";
import TemplateSurvey from "./Components/TemplateSurvey/Index";

function App() {
  loadProgressBar({ easing: "ease", speed: 500 });

  return (
    <Router>
      <Switch>
        <Redirect exact from="/" to="/login" />
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/service-terms" component={ServiceTerms} />
        <Route path="/dashboard" component={AppLayout} />
      </Switch>
    </Router>
  );
}

export default App;

export const AppContent = () => {
  const dispatcher = useDispatch();
  useEffect(() => {
    dispatcher(fetchUserInfo());
  }, []);

  return (
    <Switch>
      <Route exact path="/dashboard" component={Dashboard} />
      <Route exact path="/dashboard/profile" component={Profile} />
      <Route exact path="/dashboard/survey-archive" component={SurveyArchive} />
      <Route exact path="/dashboard/survey" component={Survey} />
      <Route exact path="/dashboard/analysis/:id" component={Analysis} />
      <Route exact path="/dashboard/update-profile" component={UpdateProfile} />
      <Route
        exact
        path="/dashboard/survey-templates"
        component={TemplateSurvey}
      />
      <Route exact path="/dashboard/create-survey" component={CreateSurvey} />
      <Route exact path="/dashboard/view-survey" component={ViewSurvey} />
      <Route exact path="/dashboard/create-questionnaire">
        <SurveyDashboard />
      </Route>
      <Route
        exact
        path="/dashboard/survey-properties"
        component={SurveyProperties}
      />
      <Route
        exact
        path="/dashboard/survey-properties2"
        component={SurveyProperties2}
      />
      <Route exact path="/dashboard/survey-summary" component={SurveySummary} />
    </Switch>
  );
};
