import React, { createContext, useContext, useState } from "react";

const SidebarContext = createContext({});

export const SideBarProvider = ({ children }) => {
  const [openSidebar, setOpenSidebar] = useState(false);

  return (
    <SidebarContext.Provider value={{ openSidebar, setOpenSidebar }}>
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebarContext = () => {
  const context = useContext(SidebarContext);
  if (!context) {
    throw new Error("Component must be within side bar context");
  }
  return context;
};
