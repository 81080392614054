import React from "react";
import styled from "styled-components";
import { Modal } from "antd";

export const ModalComponent = ({
  closable = false,
  onCancel,
  children,
  visible,
}) => {
  return (
    <StyledModal
      centered={true}
      closable={closable}
      footer={false}
      onCancel={onCancel}
      visible={visible}
      style={{ padding: "none" }}
    >
      {children}
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  width: 50vw;
  padding: none;
  .ant-modal-body {
    padding: 10px 0;
    background: ${(props) => props.theme.appForeground};
    border: 1px solid white;
  }
`;
