import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { Spring } from /* 'react-spring/renderprops' */ 'react-spring';
import { CreateQuestionnaire } from './CreateQuestionnaire';
//import Navbar from '../../../components/atoms/Navbar/index';
import { device } from '../../../globalAsset/mediaQueries';
import { QuestionContext } from './questionContext';
import { QuestionDashboard } from './QuestionDashboard';
import { Button, Modal, Table, Row, Col } from 'antd';
import { FiSave, FiToggleLeft, FiCornerUpLeft } from 'react-icons/fi';
import { questionTypes } from './questionTypes';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { notificationAlert } from '../../../utils/notificationAlert';
import { questionModeEnum } from './questionMode';
import { EditTemplate } from './molecule/EditTemplateTitleForm';
import { theme } from '../../../globalAsset/theme';
import {
  saveSingleQuestion,
  fetchSingleSurvey,
  selectSurveySlice,
  getSurveyProperties,
} from '../slice';
import { getQuestionLength } from '../../../utils/questionRange';

import { Value, Review } from '../SampleBalanceProps/Style';
import { getDataSource } from '../../../utils/objectToTableDataSource';
import { specialCharStringToArray } from '../../../utils/specialCharStringToArray';
import { getQuestionRange } from '../../../utils/questionRange';
import { usePaystackPayment } from 'react-paystack';
import { SideEffect } from '../../../Services';
import { getPricePerQuestion } from '../../../utils/questionRange';
import { Gender, Settlement, State, MonthlyIncome,AgeGroup, Education  } from '../SampleBalanceProps/stepContent';
import  SampleBalanceProp2  from '../SampleBalanceProps/index-two';
import { Back } from '../../../globalComponents/globalStyle';

const SurveyPropertyEnum = {
  STATE: 'STATE',
  GENDER: 'GENDER',
  SETTLEMENT: 'SETTLEMENT',
  INCOME: 'INCOME',
  AGE: 'AGE',
  EDUCATION: 'EDUCATION'
}

//let publicKey = 'pk_test_4d94ce57d841e91d2aae3e1d4a5dd4c165e47954';
let publicKey = 'pk_live_40600395406a22d6d9944c803482a2f7c20f9b09';

const config = {
  reference: new Date().getTime().toString(),
  email: 'user@example.com',
  amount: 20000,
  publicKey: publicKey,
};

const transformProperty = (data) => {
  console.log('transformProperty', data);
  return (
    <ul>
      {specialCharStringToArray(data).map((item, index) => {
        return <li key={index}>{item}</li>;
      })}
    </ul>
  );
};





export function ViewSurvey(props) {
  const [ currentStepForSampleBalanceProp2, setCurrentStepForSampleBalanceProp2] = useState(0);
  const [editSurveyPropertyProps, setEditSurveyPropertyProps] = useState({
    visible: false,
    loading: false
  })
  const [selectedSurveyProperty, setSelectedSurveyProperty] = useState('')
  const [questionList, setQuestionList] = useState([]);
  const [questionMode, setQuestionMode] = useState(
    questionModeEnum.screeningQuestion,
  );
  const [isSavingTemplate, setIsSavingTemplate] = useState(false);
  const [refinedQuestionList, setrefinedQuestionList] = useState([]);
  const [tracker, setTracker] = useState(0);
  const [isUnsavedQuestionModalVisible, setIsUnsavedQuestionModalVisible] =
    useState(false);
  const [saveAndSwitch, setSaveAndSwitch] = useState(false);
  //const activeTemplate = useSelector((state) => state.activeTemplate);
  const saveTemplateQuestionNotify = useSelector(
    (state) => state.saveTemplateQuestionNotify,
  );
  const deleteQuestionNotification = useSelector(
    (state) => state.deleteQuestionNotification,
  );
  const [showTemplateNameModal, setShowTemplateNameModal] = useState(false);
  const updateTemplateNameNotify = useSelector(
    (state) => state.updateTemplateNameNotify,
  );
  const [newTemplateName, setNewTemplateName] = useState('');
  const { surveyId: id } = localStorage;
  const { singleSurvey } = useSelector(selectSurveySlice);
  const {
    surveyProperties: {
      survey_name,
      question_range,
      sample_needed,
      ...otherProperties
    },
  } = useSelector(selectSurveySlice);
  const dispatcher = useDispatch();
  const history = useHistory();
  const [surveyDetailProps, setSurveyDetailsProps] = useState({
    isVisible: false,
  });
  const [surveySentProps, setSurveySentProps] = useState({
    isVisible: false,
  });
  const userInfo = useSelector((state) => state.globalData.data);

  const column = [
    {
      title: 'Survey Property',
      dataIndex: 'title',
      key: 'title',
      render: (data) => (
        <h4 style={{ textTransform: 'capitalize' }}>
          {data.split('_').join(' ')}
        </h4>
      ),
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      render: transformProperty,
    }
  ];

  const questionBackendFormat = (item) => {
    let screeningType = questionMode === questionModeEnum.questionnaire ? 0 : 1;
    if (
      item.type === questionTypes.singleLine ||
      item.type === questionTypes.multipleLine
    ) {
      return {
        question: item.question,
        question_type: 'single_input',
        options: null,
        category: null,
        survey_id: parseInt(id),
        screening_type: screeningType,
        isSaved: item.isSaved,
      };
    } else {
      return {
        question: item.question,
        question_type: item.type,
        options: item.options
          .map((item) => item.replace(/,/g, '<!&&!>'))
          .toString(),
        category: item.categories
          ? item.categories
              .map((item) => item.replace(/,/g, '<!&&!>'))
              .toString()
          : null,
        survey_id: parseInt(id),
        screening_type: screeningType,
        isSaved: item.isSaved,
      };
    }
  };

  const questionFrontendFormat = (item) => {
    console.log(item);
    if (item.question_type === questionTypes.singleLine) {
      return {
        question: item.question,
        type: item.question_type,
        questionId: item.question_id,
        isSaved: true,
      };
    } else {
      let newOptions =
        typeof item.options === 'string'
          ? item.options.split(',').map((item) => item.replace(/<!&&!>/g, ','))
          : item.options.map((item) => item.replace(/<!&&!>/g, ','));

      let newCategories =
        typeof item.category === 'string'
          ? item.category.split(',').map((item) => item.replace(/<!&&!>/g, ','))
          : item.category.map((item) => item.replace(/<!&&!>/g, ','));

      return {
        question: item.question,
        type: item.question_type,
        options: newOptions,
        categories: newCategories.filter((item) => item !== 'null'),
        questionId: item.question_id,
        isSaved: true,
      };
    }
  };

  const questionAllowanceFn = useCallback(() => {
    let otherQuestionList = [];
    if (questionMode === questionModeEnum.screeningQuestion) {
      otherQuestionList = singleSurvey?.questionnaire ?? [];
    } else if (questionMode === questionModeEnum.questionnaire) {
      otherQuestionList = singleSurvey?.screening_question ?? [];
    }

    let qLength = getQuestionLength(
      otherQuestionList.map(questionFrontendFormat),
      questionList,
    );

    return singleSurvey?.question_range - qLength;
  }, [singleSurvey, questionMode, questionList]);

  useEffect(() => {
    let newQuestionList;
    if (questionMode === questionModeEnum.questionnaire) {
      newQuestionList = singleSurvey.questionnaire
        ?.slice()
        .map(questionFrontendFormat);
    } else if (questionMode === questionModeEnum.screeningQuestion) {
      newQuestionList = singleSurvey.screening_question
        ?.slice()
        .map(questionFrontendFormat);
    }

    setQuestionList(newQuestionList ?? []);
  }, [singleSurvey, questionMode]);

  useEffect(() => {
    if (saveTemplateQuestionNotify !== '') {
      if (tracker > 0 && tracker < refinedQuestionList.slice().length) {
        console.log({ refinedQuestionList, tracker });
        dispatcher(
          saveSingleQuestion({ data: refinedQuestionList.slice()[tracker] }),
        );
        setTracker((prevState) => prevState + 1);
      }

      if (
        tracker === refinedQuestionList.slice().length &&
        refinedQuestionList.length > 0
      ) {
        notificationAlert(
          'success',
          'Questions Added',
          `${refinedQuestionList.slice().length} questions have been added`,
        );
        setIsSavingTemplate(false);
        setTracker(0);
        if (saveAndSwitch) {
          setSaveAndSwitch(false);
          handleQuestionModeSwitch();
        }
      }
    }
  }, [saveTemplateQuestionNotify, tracker]);

  useEffect(() => {
    if (deleteQuestionNotification !== '') {
      if (deleteQuestionNotification === 'success') {
        notificationAlert(
          'success',
          'Question deleted',
          'Question was deleted',
        );
      }

      if (deleteQuestionNotification === 'error') {
        notificationAlert('error', 'Failed', 'Please try again');
      }

      /*  dispatcher(deleteQuestionNotificationAction(''));
       dispatcher(fetchSingleTemplateAction(id)); */
    }
  }, [deleteQuestionNotification]);

  useEffect(() => {
    dispatcher(fetchSingleSurvey({ surveyId: localStorage.surveyId }));
    if (saveAndSwitch) {
      setSaveAndSwitch(false);
      handleQuestionModeSwitch();
    }
  }, [saveTemplateQuestionNotify, tracker]);

  useEffect(() => {
    (async () => {
      try {
        if (localStorage.surveyId) {
          dispatcher(
            getSurveyProperties({ surveyId: localStorage.surveyId }),
          );
          await dispatcher(
            fetchSingleSurvey({ surveyId: localStorage.surveyId }),
          );
        } else {
          history.goBack();
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const saveSurvey = () => {
    const finalQuestionList = questionList.slice().map(questionBackendFormat);

    setrefinedQuestionList(finalQuestionList.filter((item) => !item.isSaved));

    console.log({ finalQuestionList });
    if (finalQuestionList.filter((item) => !item.isSaved).length > 0) {
      setIsSavingTemplate(true);
      dispatcher(
        saveSingleQuestion({
          data: finalQuestionList.filter((item) => !item.isSaved)[0],
        }),
      );
      setTracker(1);
    }

    if (questionMode === questionModeEnum.questionnaire) {
      /* dispatcher(setTemplateQuestionnaireAction(finalQuestionList)); */
    } else {
      /*  dispatcher(setTemplateScreeningQuestionAction(finalQuestionList)); */
    }
  };

  const handleSurveySubmit = (e) => {
    e.preventDefault();
    saveSurvey();
  };

  const handleQuestionModeSwitch = () => {
    if (questionList.some((item) => !item.isSaved)) {
      setIsUnsavedQuestionModalVisible(true);
      return;
    }

    setrefinedQuestionList([]);
    setTracker(0);
    /*  dispatcher(saveTemplateQuestionNotifyAction('')); */
    let formatedQuestionList = questionList.slice().map(questionBackendFormat);

    if (questionMode === questionModeEnum.questionnaire) {
      // dispatcher(setTemplateQuestionnaireAction(formatedQuestionList));
      setQuestionMode(questionModeEnum.screeningQuestion);
    } else {
      /*  dispatcher(setTemplateScreeningQuestionAction(formatedQuestionList)); */
      setQuestionMode(questionModeEnum.questionnaire);
    }
  };

  useEffect(() => {
    if (updateTemplateNameNotify !== '') {
      setShowTemplateNameModal(false);
      if (updateTemplateNameNotify === 'success') {
        notificationAlert(
          'success',
          'Template Changed',
          `The template name has been changed to ${newTemplateName}`,
        );
        /*  dispatcher(setActiveTemplateNameAction(newTemplateName)); */
      }

      if (updateTemplateNameNotify === 'error') {
        notificationAlert('error', 'Failed', `Please try again`);
      }

      /* dispatcher(updateTemplateNameNotificationAction('')); */
    }
  }, [updateTemplateNameNotify]);

  const onFinish = (values) => {
    values.id = id;
    setNewTemplateName(values.name);
    /* dispatcher(updateTemplateNameAction(values)); */
  };

  const onEditTemplateTitleShow = () => {
    setShowTemplateNameModal(true);
  };

  const handleModalCancel = () => {
    setShowTemplateNameModal(false);
  };

  const handleCancelUnsavedQuestionModal = () => {
    setIsUnsavedQuestionModalVisible(false);
    if (questionMode === questionModeEnum.questionnaire) {
      setQuestionMode(questionModeEnum.screeningQuestion);
    } else {
      setQuestionMode(questionModeEnum.questionnaire);
    }
  };

  const handleSurveySubmitAndChangePage = () => {
    setSaveAndSwitch(true);
    setIsUnsavedQuestionModalVisible(false);
    saveSurvey();
  };

  const onSuccess = (reference) => {
    (async () => {
      try {
        const data = {
          survey_id: localStorage.surveyId,
          reference: reference?.reference,
          amount:
            `${sample_needed *
            getPricePerQuestion(getQuestionRange(question_range))}`,
        };


         await SideEffect.fundSurvey({data});
        const res = await SideEffect.sendSurvey({
          data: { survey_id: localStorage.surveyId },
        }); 
        console.log('send survey', res);
        setSurveySentProps((prevState) => ({ ...prevState, isVisible: true }));
      } catch (error) {
        console.log(error.response);
        if (error.response) {
          notificationAlert(
            'error',
            'Error Occurred',
            error.response.data.message ?? 'Please try again later',
          );
        } else {
          notificationAlert(
            'error',
            'Error Occurred',
            'Please try again later',
          );
        }

  
      }
    })();

    // Implementation for whatever you want to do with reference and after success call.
    console.log(reference?.reference);
  };

  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log('closed');
  };

  const initializePayment = usePaystackPayment({
    reference: new Date().getTime().toString(),
    email: userInfo.email,
    amount:
      sample_needed *
      getPricePerQuestion(getQuestionRange(question_range)) *
      100,
    publicKey: publicKey,
  });


  const finishEdittingSurveyProperty = () => {
    setEditSurveyPropertyProps(prevState => ({...prevState, visible: false}));
   /*  notificationAlert('success', `${selectedSurveyProperty.split('_').join(' ')} Updated`, `${selectedSurveyProperty.split('_').join(' ')} was successfully updated`); */
    dispatcher(
      getSurveyProperties({ surveyId: localStorage.surveyId }),
    );
  }
  return (
    <Spring
      from={{ opacity: 0.1, marginTop: +15 }}
      to={{ opacity: 1, marginTop: 0 }}
      config={{ duration: 700, delay: 200 }}
    >
      {(springProps) => (
        <QuestionContext.Provider
          value={{
            questionList,
            setQuestion: setQuestionList,
            questionMode,
            questionAllowance: questionAllowanceFn(),
          }}
        >
          <StyledContainer style={springProps}>
            
            <StyledActionContainer>
            <Back />
              <StyledSaveTemplateButton
                onClick={handleQuestionModeSwitch}
                icon={<StyledFiToggleLeft />}
                size='large'
              >
                Switch to{' '}
                {questionMode === questionModeEnum.questionnaire
                  ? questionModeEnum.screeningQuestion
                  : questionModeEnum.questionnaire}
              </StyledSaveTemplateButton>
             
              <StyledSaveTemplateButton
                onClick={() =>
                  setSurveyDetailsProps((prevState) => ({
                    ...prevState,
                    isVisible: true,
                  }))
                }
                loading={isSavingTemplate}
                icon={<StyledFiSave />}
                size='large'
              >
                View Survey Details
              </StyledSaveTemplateButton>
            </StyledActionContainer>
            <StyledHeader onDoubleClick={onEditTemplateTitleShow}>
              {singleSurvey?.survey_name}
            </StyledHeader>
            <StyledBodyContainer>
              <QuestionDashboard surveyName={questionMode} />
            </StyledBodyContainer>
          </StyledContainer>
          <Modal
            title='Survey Details'
            visible={surveyDetailProps.isVisible}
            onCancel={() =>
              setSurveyDetailsProps((prevState) => ({
                ...prevState,
                isVisible: false,
              }))
            }
            okText='Save'
            cancelText='Discard'
            footer={false}
          >
           
              <Review>
              <h4>
                Survey Name: <span>{survey_name}</span>
              </h4>
            </Review>
            
             
  
           
            <Review>
              <h4>Estimated Bill:</h4>
              <Value
                prefix='₦'
                value={
                  sample_needed *
                  getPricePerQuestion(getQuestionRange(question_range))
                }
              />
            </Review>
            <Row>
              <Col sm={20}>
              <Review>
              <h4>
                Samples Needed: <span>{sample_needed}</span>{' '}
              </h4>
            </Review>
              </Col>
            

            </Row>
            <Row>
              <Col sm={20}>
              <Review>
              <h4>
                Question Limit: <span>{getQuestionRange(question_range)}</span>
              </h4>
            </Review>
              </Col>
        
            </Row>
           
            <br />
            <Table
              /* title={() => 'Survey Properties'} */
              pagination={false}
              columns={column}
              dataSource={getDataSource(otherProperties)}
            />
            <br />
            <Row>
              <Col>
                <Button
                  onClick={() => history.replace('/dashboard/create-survey')}
                  text='Create Questions'
                />
              </Col>
            </Row>
          </Modal>

        </QuestionContext.Provider>
      )}
    </Spring>
  );
}

const StyledContainer = styled.div`
  width: 100%;
`;
const StyledBodyContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  position: relative;

  @media ${device.laptop} {
    display: flex;
    justify-content: space-around;
  }
`;

/* const StyledSaveTemplateButton = styled.button`
    background-color: ${theme.lightgreen};
    border-radius: 50%;
    padding: 30px;
    height: 5vh;
    width: 5vh;
    display: flex;
    justify-content: center;
    align-items:center;
    position: absolute;
    top: 80%;
    left: 90%;
    border: none;
    box-shadow: 0px 1px 3px 0px rgba(188,202,209,0.5);
    color: #fff;

    &:focus{
        border: none;
        outline: none;
    }
` */

const StyledSaveTemplateButton = styled(Button)`
  padding-left: 30px;
  padding-right: 30px;
  color: #249b00;
  border: none;
  background-color: transparent !important;

  &:hover,
  &:focus {
    color: #249b00;
    border: none;
  }
`;

const StyledActionContainer = styled.div`
  width: 90%;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  margin-top: 20px;
`;
const StyledFiSave = styled(FiSave)`
  margin-right: 10px;
`;

const StyledFiToggleLeft = styled(FiToggleLeft)`
  margin-right: 10px;
`;

const StyledHeader = styled.h3`
  text-align: center;
  cursor: pointer;
  margin-top: 30px;
`;

const StyledUnsavedQuestionModal = styled.div``;

const StyledUnsavedQuestionModalFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const StyledButton = styled(Button)`
  background-color: ${theme.lightgreen};
  outline: none;
  border: none;
  color: #fff;

  &:hover,
  &:focus {
    background-color: ${theme.lightgreen};
    color: #fff;
  }
`;

const StyledCancelButton = styled(StyledButton)`
  background-color: #ff0000;

  &:hover,
  &:focus {
    background-color: #ff0000;
  }
`;

const StyledSaveButton = styled(StyledButton)`
  margin-left: 10px;
`;
