import React from "react";
import { Button as AntButton } from "antd";
import styled from "styled-components";
import { desktop, mobile } from "../globalAsset/fontSize";
import { device } from "../globalAsset/mediaQueries";
import { theme } from "../globalAsset/theme";

export const Button = ({
  colorProp,
  width,
  htmlType,
  disabled,
  text,
  onClick,
  style,
  loading,
}) => {
  return (
    <StyledButton
      style={{ ...style }}
      disabled={disabled ? true : false}
      width={width}
      size="large"
      onClick={onClick ? () => onClick() : null}
      htmlType={htmlType ? htmlType : {}}
      colorProp={colorProp}
      loading={loading}
    >
      {text}
    </StyledButton>
  );
};

const StyledButton = styled(AntButton)`
  border: none;
  cursor: pointer;
  background: ${theme.secondaryDark};
  font-weight: 500;
  border-radius: 5px;
  color: white;
  width: ${(props) => props.width};

  :active,
  :focus,
  :hover {
    box-shadow: none;
    transition: all 0.5s ease;
    background: ${theme.secondaryDark};
    color: white;
  }
`;

const Container = styled.div`
  text-align: center;
  ${"" /* margin-top: -20px; */}
  @media ${device.laptop} {
    width: 100%;
    margin: 0;
    height: 2.5vw;
    text-align: center;
  }
`;
