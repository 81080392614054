import React, { useEffect } from "react";
import { Back, ComponentContainer } from "../../globalComponents/globalStyle";
import { Row, Col, Table } from "antd";
import { Value, Review } from "./SampleBalanceProps/Style";
import { useHistory } from "react-router-dom";
import { BigNav } from "../../globalComponents/Nav";
import { useSelector } from "react-redux";
import { Button } from "../../globalComponents/Index";
import { useDispatch } from "react-redux";
import { getSurveyProperties, selectSurveySlice, fetchSingleSurvey } from "./slice";
import { getQuestionRange } from "../../utils/questionRange";
import { specialCharStringToArray } from "../../utils/specialCharStringToArray";
import { getDataSource } from "../../utils/objectToTableDataSource";
import { getPricePerQuestion } from '../../utils/questionRange';
import useScreenSize from "../../utils/useSmallScreen";
import { screenSizes } from "../../globalAsset/mediaQueries";

const transformProperty = (data) => {
  return (
    <ul>
      {specialCharStringToArray(data).map((item, index) => {
        return <li key={index}>{item}</li>;
      })}
    </ul>
  );
};

const column = [
  {
    title: "Survey Property",
    dataIndex: "title",
    key: "title",
    render: (data) => <h4 style={{ textTransform: 'capitalize' }}>{data.split('_').join(' ')}</h4>
  },
  {
    title: "Value",
    dataIndex: "value",
    key: "value",
    render: transformProperty,
  },
];

export const SurveySummary = () => {
  const { isWithinScreenSize } = useScreenSize(screenSizes.tablet);
  const { goBack, location, replace } = useHistory();
  const survey = useSelector((state) => state.surveyModalReducer);

  const {
    surveyProperties: {
      survey_name,
      question_range,
      sample_needed,
      ...otherProperties
    },
    singleSurvey
  } = useSelector(selectSurveySlice);
  const dispatcher = useDispatch();
  console.log({ singleSurvey })

  useEffect(() => {
    (async () => {
      try {
        if (localStorage.surveyId) {
          dispatcher(fetchSingleSurvey({ surveyId: localStorage.surveyId }))
          dispatcher(
            getSurveyProperties({ surveyId: localStorage.surveyId })
          );
        } else {
          goBack();
        }
      } catch (error) { }
    })();
  }, []);

  return (
    <>
      <BigNav title="Survey Details" />
      <ComponentContainer style={{ width: isWithinScreenSize ? "100%" : "60%" }}>
        <Back />
        {/* <h4>Survey Name </h4>
        <h4>the price is {location.state.surveyPrice}</h4> */}
        <Review>
          <h4>
            Survey Name: <span>{survey_name}</span>
          </h4>
        </Review>
        <Review>
          <h4>Estimated Bill:</h4>
          <Value prefix="₦" value={
            singleSurvey.amount
            /* sample_needed *
            getPricePerQuestion(getQuestionRange(question_range)) */
          } />
        </Review>
        <Review>
          <h4>
            Samples Needed: <span>{sample_needed}</span>{" "}
          </h4>
        </Review>
        <Review>
          <h4>
            Question Limit: <span>{getQuestionRange(question_range)}</span>
          </h4>
        </Review>
        <br />
        {singleSurvey.respondent_type !== "unassigned" && <Table
          /* title={() => 'Survey Properties'} */
          pagination={false}
          columns={column}
          dataSource={getDataSource(otherProperties)}
        />}
        <br />
        <Row>
          <Col>
            <Button
              onClick={() => replace("/dashboard/create-questionnaire")}
              text="Create Questions"
            />
          </Col>
        </Row>
      </ComponentContainer>
    </>
  );
};
