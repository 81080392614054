import React from "react";
import {
  Back,
  ComponentContainer,
} from "../../../globalComponents/globalStyle";
import { Row, Col } from "antd";
import { Value, Review } from "./Style";
import { useHistory } from "react-router-dom";
import { BigNav } from "../../../globalComponents/Nav";
import { useSelector } from "react-redux";
import { Button } from "../../../globalComponents/Index";

export const CreateSurvey = () => {
  return (
    <>
      <BigNav title="Create Survey" />
      <ComponentContainer>Cr</ComponentContainer>
    </>
  );
};
