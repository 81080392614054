import React, { useState, useRef, useEffect, useMemo } from "react";
import { Checkbox, Radio, Input, Select, Col, Row } from "antd";
import styled from "styled-components";

const { Option } = Select;

const { TextArea } = Input;
const CheckboxGroup = Checkbox.Group;

export const CheckboxOption = (props) => {
  const [checkedList, setCheckedList] = useState([]);

  const onChange = (checkedValues) => {
    setCheckedList(checkedValues);
  };

  const optionList = props?.options?.map((item, index) => {
    return (
      <div key={index}>
        <Checkbox value={item}>{item}</Checkbox>
        <br />
      </div>
    );
  });

  return <CheckboxGroup onChange={onChange}>{optionList}</CheckboxGroup>;
};

export const RadioGroupOption = (props) => {
  const optionList = props.options?.map((item, index) => {
    return (
      <>
        <Radio key={index} value={item}>
          {item}
        </Radio>
        <br />
      </>
    );
  });
  return (
    <Radio.Group onChange={props.onChange} value={props.value}>
      {optionList}
    </Radio.Group>
  );
};

export const SingleInput = (props) => {
  return <Input onChange={props.onChange} />;
};

export const MultiLineInput = (props) => {
  return <TextArea rows={4} onChange={props.onChange} />;
};

export const DropDown = (props) => {
  console.log({ dropDown: props.options });
  const optionList = props?.options?.map((item, index) => {
    return (
      <Option key={index} value={item}>
        {item}
      </Option>
    );
  });

  return (
    <StyledSelect defaultValue={props.options[0]} onChange={props.onChange}>
      {optionList}
    </StyledSelect>
  );
};

export const Matrix_old = (props) => {
  console.log(props);
  const catList = props.categories?.map((cat, index) => {
    return (
      <CategoryGroup
        onChange={props.onChange}
        category={cat}
        options={props.options}
        showOption={index === 0}
        key={index}
      />
    );
  });

  return <div style={{ width: "100%", overflowX: "auto" }}>{catList}</div>;
};

export const Matrix = (props) => {
  console.log(props);

  const header = ["option0", ...props.options].map((option, index, options) => {
    const remainder = 24 - options.length * Math.floor(24 / options.length);
    return (
      <Col

        span={
          index === 0
            ? Math.floor(24 / options.length)
            : Math.floor(24 / options.length) + remainder / (options.length - 1)
        }
        key={`${option}-${index}`}
        style={{ visibility: index === 0 && "hidden", textAlign: 'center' }}
      >
        {option}
      </Col>
    );
  });

  const categories = props.categories.map((cat) => {
    console.log("CATEGORIES", Math.floor(24 / (props.options.length + 1)));
    return (
      <Row justify='center' key={cat} style={{ width: "100%", overflowX: "auto" }}>
        <Col span={Math.floor(24 / (props.options.length + 1))}>{cat}</Col>

        {props.options.map((option) => (
          <Col style={{ textAlign: 'center'}}  key={option} span={Math.floor(24 / (props.options.length + 1))}>
            <Radio />
          </Col>
        ))}
      </Row>
    );
  });

  return (
    <div style={{ width: "100%", overflowX: "auto" }}>
      <Row style={{ width: "100%", overflowX: "auto" }}>{header}</Row>
      {categories}
    </div>
  );
};

const CategoryGroup = (props) => {
  const radioList = props.options?.map((item, index) => {
    return (
      <SingleRadio
        onChange={props.onChange}
        key={index}
        category={props.category}
        option={item}
        showCategory={index === 0}
        showOption={props.showOption}
      />
    );
  });
  return <StyleMatrixRadioGroup>{radioList}</StyleMatrixRadioGroup>;
};

const SingleRadio = ({
  showOption,
  showCategory,
  category,
  onChange,
  option,
}) => {
  const [optionHeight, setObjectHeight] = useState(10);
  const inputRef = useRef();
  const catRef = useRef();

  React.useEffect(() => {
    setObjectHeight(catRef.current?.getBoundingClientRect()?.height);
    console.log("change");
  }, [category]);

  return (
    <StyledSingleRadio>
      {showCategory ? (
        <div
          ref={catRef}
          className="category"
          style={{
            textAlign: "left",
            overflow: "auto",
            visibility: showCategory ? "visible" : "hidden",
            width: showCategory ? "15vw" : "1vw",
          }}
        >
          <p>{category}</p>
        </div>
      ) : (
        <div
          ref={catRef}
          className="category"
          style={{
            textAlign: "left",
            overflowX: "auto",
            visibility: showCategory ? "visible" : "hidden",
            width: "1vw",
          }}
        >
          <div style={{ width: "15vw" }}>
            <p>{category}</p>
          </div>
        </div>
      )}
      <div>
        {showOption && (
          <>
            <label for="age2">{option}</label>
            <br />
          </>
        )}
        <StyledCustomRadio style={{ height: optionHeight }}>
          <input
            ref={inputRef}
            type="radio"
            id="age3"
            name={category}
            value={option}
          />
          <div
            /* onClick={() => onChange(option, category, inputRef)} */
            className="checkmark"
          >
            <div className="checkmark-inner"></div>
          </div>
        </StyledCustomRadio>
      </div>
    </StyledSingleRadio>
  );
};

const StyledCustomRadio = styled.div`
  display: grid;
  place-items: center;
  margin-top: 40px;

  /* Hide the browser's default radio button */
  & input {
    display: none;
  }

  /* Create a custom radio button */
  & .checkmark {
    height: 15px;
    width: 15px;
    background-color: transparent;
    border: 1px solid #ccc;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .checkmark-inner {
    height: 7px;
    width: 7px;
    border-radius: 50%;
    cursor: pointer;
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    border-color: #007aaf;
  }

  /* Show the indicator (dot/circle) when checked */
  & input:checked ~ .checkmark .checkmark-inner {
    display: block;
    background-color: #007aaf;
  }

  & input:checked ~ .checkmark {
    border-color: #007aaf;
  }
`;

const StyleMatrixRadioGroup = styled.div`
  display: flex;
`;
const StyledSingleRadio = styled.div`
  display: flex;
  align-items: flex-end;

  & .category {
    /*   width: 10vw; */
    /*    height: 3vw; */
  }

  & div {
    text-align: center;
    width: 8vw;
  }

  & p {
    margin-bottom: 2px;
  }
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;

const StyledRadio = styled.div`
  display: flex;
  align-items: center;

  & > p {
    margin-bottom: 0px;
    margin-right: 10px;
    color: black;
    font-size: 14px;
    border: 1px solid yellow;
  }
`;

/* const StyledCheckboxGroup  = styled(CheckboxGroup)`
    .ant-checkbox-checked .ant-checkbox-inner{
        background-color: #0F6281 !important;
    }
` */
