import React, { useState, useEffect } from "react";
import { BigNav } from "../../globalComponents/Nav";
import SurveyCard from "./SurveyCard";
import { AiOutlineBarChart } from "react-icons/ai";
import { Row, Col, Empty, Typography, Pagination} from "antd";
import { ComponentContainer } from "../../globalComponents/Index";
import { useDispatch, useSelector } from "react-redux";
import { showDrawer } from "../../globalComponents/slices/surveyModalSlice";
import {
  fetchAssignedSurvey,
  fetchPurchasedSurvey,
  selectGlobalData,
} from "./slice";
import {
  CounterContainer,
  CardContainer,
  Button,
  RedeemableIconContainer,
} from "./Style";

const Dashboard = () => {
  const [page, setPage] = useState(1);

  const dispatcher = useDispatch();
  const userInfo = useSelector((state) => state.globalData.data);
  const { paginationProps, purchasedSurveys } =
    useSelector(selectGlobalData);
  useEffect(() => {
    //dispatcher(fetchAssignedSurvey(page));
    dispatcher(fetchPurchasedSurvey({ page }));
  }, []);

  const handlePagination = async (page, pageTotal) => {
    console.log({ page, pageTotal });

    try {
      await dispatcher(fetchPurchasedSurvey({  page }));
    } catch (error) {}
  };

  /*  const tempSurvey = [
    {
      num_questions: 20,
      survey_name: "Survey Testing 1234",
      responses: 12,
      string_status: "Active",
   
    },
    {
      num_questions: 20,
      survey_name: "Survey Testing 1234",
      responses: 12,
      string_status: "Active",
    },
    {
      num_questions: 20,
      survey_name: "Survey Testing 1234",
      responses: 12,
      string_status: "Active",
    },
    {
      num_questions: 20,
      survey_name: "Survey Testing 1234",
      responses: 12,
      string_status: "Active",
    },
    {
      num_questions: 20,
      survey_name: "Survey Testing 1234",
      responses: 12,
      string_status: "Active",
    },
    {
      num_questions: 20,
      survey_name: "Survey Testing 1234",
      responses: 12,
      string_status: "Active",
    },
    {
      num_questions: 20,
      survey_name: "Survey Testing 1234",
      responses: 12,
      string_status: "Active",
    },
  ]; */

  const surveyCardArray = [...purchasedSurveys.surveys].map((survey, id) => (
    <Col xs={24} sm={12} md={6} key={id}>
      <SurveyCard
        questionNumber={survey.num_questions}
        questionTitle={survey.survey_name}
        respondents={survey.responses}
        active={survey.string_status === "Active"}
        id={survey.id}
      />
    </Col>
  ));

  /* const surveyCardArray = tempSurvey.map((survey, id) => (
    <Col xs={24} sm={12} md={6} key={id}>
      <SurveyCard
        questionNumber={survey.num_questions}
        questionTitle={survey.survey_name}
        respondents={survey.responses}
        active={survey.string_status === "Active"}
        id={survey.id}

      />
    </Col>
  )); */

  return (
    <>
      <BigNav title="Dashboard" />
      <ComponentContainer>
        <CardContainer>
          <RedeemableIconContainer>
            <AiOutlineBarChart />
          </RedeemableIconContainer>
          <div>
            <Typography.Title style={{ margin: 0 }} level={4}>
              Total Survey
            </Typography.Title>
            <Typography.Title style={{ margin: 0 }} level={3}>
              {purchasedSurveys.total_survey}
            </Typography.Title>
          </div>
        </CardContainer>
        {Boolean(surveyCardArray.length) && (
          <Button onClick={() => dispatcher(showDrawer())}>
            Conduct Survey
          </Button>
        )}

        <br />
        {Boolean(surveyCardArray.length) ? (
          <Row gutter={[16, 16]}> {surveyCardArray} </Row>
        ) : (
          <Empty description="Click on the button to create survey">
            <Button onClick={() => dispatcher(showDrawer())}>
              Conduct Survey
            </Button>
          </Empty>
        )}
        <div style={{ display: "grid", placeItems: "center" }}>
          <Pagination
            onChange={handlePagination}
            defaultCurrent={1}
            total={10 * paginationProps.pages}
          />
        </div>
      </ComponentContainer>
    </>
  );
};

export default Dashboard;
