import React, { useState } from "react";
import { BigNav } from "../../globalComponents/Nav";
import styled from "styled-components";
import { device } from "../../globalAsset/mediaQueries";
import { Steps, Popover } from "antd";
import { useHistory } from "react-router-dom";
import {
  UpdateAddressComponent,
  UpdateLocationComponent,
  UpdateSponsorTypeComponent,
} from "./StepContent";
import { Back, ComponentContainer } from "../../globalComponents/globalStyle";
import { desktop } from "../../globalAsset/fontSize";
const UpdateProfile = () => {
  const history = useHistory();
  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const [current, setCurrent] = useState(0);
  const customDot = (dot, { status, index }) => (
    <Popover
      content={
        <span>
          {/* step {index} status: {status} */}
          STEP {index + 1}
        </span>
      }
    >
      {dot}
    </Popover>
  );
  const { Step } = Steps;

  const steps = [
    {
      question: "Update Sponsor Type",
      id: "sponsorType",
      content: (
        <UpdateSponsorTypeComponent buttonText="Next" onClick={() => next()} />
      ),
    },
    {
      question: "Update Address",
      id: "address",
      content: (
        <UpdateAddressComponent
          backText={"Back"}
          backFunction={() => prev()}
          buttonText="Next"
          onClick={() => next()}
        />
      ),
    },
    {
      question: "Update Location",
      id: "location",
      content: (
        <UpdateLocationComponent
          backText={"Back"}
          backFunction={() => prev()}
          buttonText="Next"
          onClick={() => history.replace("/profile")}
        />
      ),
    },
  ];
  const StepsVariable = (
    <div>
      <Steps
        size="small"
        direction="horizontal"
        style={{
          paddingTop: "10px",
          marginLeft: "-3.250vw",
          // boxSizing: "border-box",
        }}
        progressDot={customDot}
        responsive
        current={current}
      >
        {steps.map((item) => (
          <Step
            size="small"
            key={item.id}
            // title={item.question}
          />
        ))}
      </Steps>
    </div>
  );
  return (
    <>
      <BigNav title="Update Profile" />
      <ComponentContainer>
        <Back />
        {StepsVariable}
        <ContentContainer>
          <h4>{steps[current].question}</h4>
          {steps[current].content}
        </ContentContainer>
      </ComponentContainer>
    </>
  );
};
export const ContentContainer = styled.div`
  padding: 10px 0px;
  @media ${device.laptop} {
    width: 25vw;
    margin: 0 20px;
    text-align: center;
    h4 {
      font-size: ${desktop.medium};
      margin: 1vw 0 2vw 0;
    }
  }
`;
export default UpdateProfile;
