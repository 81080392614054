import React from "react";
export const LoginSvg = () => {
  return (
    <svg
      id="bafbdcdd-db4a-4743-9ceb-46364dc67d6e"
      data-name="Layer 1"
      xmlns="https://www.w3.org/2000/svg"
      width="100%"
      height="50vh"
      viewBox="0 0 851 746.0542"
    >
      <title>ideas</title>
      <ellipse cx="474.5" cy="691.5542" rx="308.5" ry="54.5" fill="#3f3d56" />
      <path
        d="M443.69315,415.65256A146.90034,146.90034,0,1,0,375.53452,470.378a47.51816,47.51816,0,1,0,68.15863-54.72543Z"
        transform="translate(-174.5 -76.9729)"
        fill="#940056"
      />
      <rect
        x="59.78151"
        y="227.30384"
        width="174.74595"
        height="13.79573"
        fill="#fff"
      />
      <rect
        x="59.78151"
        y="250.29672"
        width="174.74595"
        height="13.79573"
        fill="#fff"
      />
      <rect
        x="59.78151"
        y="273.28961"
        width="174.74595"
        height="13.79573"
        fill="#fff"
      />
      <path
        d="M756.30685,306.37888a146.90034,146.90034,0,1,1,68.15863,54.72543,47.51816,47.51816,0,1,1-68.15863-54.72543Z"
        transform="translate(-174.5 -76.9729)"
        fill="#161262"
      />
      <rect
        x="790.97254"
        y="195.00306"
        width="174.74595"
        height="13.79573"
        transform="translate(1582.19103 326.82895) rotate(-180)"
        fill="#fff"
      />
      <rect
        x="790.97254"
        y="217.99595"
        width="174.74595"
        height="13.79573"
        transform="translate(1582.19103 372.81473) rotate(-180)"
        fill="#fff"
      />
      <rect
        x="790.97254"
        y="240.98884"
        width="174.74595"
        height="13.79573"
        transform="translate(1582.19103 418.8005) rotate(-180)"
        fill="#fff"
      />
      <path
        d="M501.21288,708.464a82.01252,82.01252,0,1,1,38.0521,30.55252,26.52879,26.52879,0,1,1-38.0521-30.55252Z"
        transform="translate(-174.5 -76.9729)"
        fill="#259A04"
      />
      <circle cx="362.5511" cy="583.8403" r="9.49088" fill="#fff" />
      <circle cx="394.93176" cy="583.8403" r="9.49088" fill="#fff" />
      <circle cx="427.31243" cy="583.8403" r="9.49088" fill="#fff" />
      <path
        d="M578.93617,366.38074s42.34638,1.1513,49.34015,3.69576,10.12111,20.7396,10.12111,20.7396l-32.87919,10.33437-24.59189-1.393-18.50788,1.30783L572.75268,374Z"
        transform="translate(-174.5 -76.9729)"
        fill="#2f2e41"
      />
      <path
        d="M580.76275,352.48561s2.78616,10.76072-3.39733,18.38S603.9475,405.6353,603.9475,405.6353l17.28539-2.971s-3.8097-33.064,1.30766-41.43662S580.76275,352.48561,580.76275,352.48561Z"
        transform="translate(-174.5 -76.9729)"
        fill="#9f616a"
      />
      <path
        d="M580.76275,352.48561s2.78616,10.76072-3.39733,18.38S603.9475,405.6353,603.9475,405.6353l17.28539-2.971s-3.8097-33.064,1.30766-41.43662S580.76275,352.48561,580.76275,352.48561Z"
        transform="translate(-174.5 -76.9729)"
        opacity="0.1"
      />
      <polygon
        points="521.22 590.132 483.081 640.709 509.109 655.905 544.931 597.296 521.22 590.132"
        fill="#9f616a"
      />
      <path
        d="M668.96761,718.53491s-14.04443-16.31874-13.419-12.67971-9.993,34.49974-.11362,37.48486,94.60062,10.90261,95.76622,1.33594S725.927,728.41415,725.927,728.41415s-15.70758-15.09625-19.19024-13.561S668.96761,718.53491,668.96761,718.53491Z"
        transform="translate(-174.5 -76.9729)"
        fill="#2f2e41"
      />
      <polygon
        points="512.663 638.435 543.126 690.333 566.125 677.014 535.407 634.525 512.663 638.435"
        fill="#9f616a"
      />
      <path
        d="M735.25164,591.93625l-9.04068,12.7935s-43.09961,59.85933-38.52238,64.6924,23.63955,23.09926,33.33415,19.5597S765.16,624.261,765.16,624.261l-8.984-35.92119Z"
        transform="translate(-174.5 -76.9729)"
        fill="#2f2e41"
      />
      <path
        d="M549.59,579.82562s9.652,18.01033,34.116,24.10848,50.02251,18.56461,50.93227,18.40824,3.48266-1.53523,4.10813,2.1038,7.33495,9.97889,7.33495,9.97889,27.02289,91.82859,37.343,91.92807,31.52877-7.29237,31.96943-10.178-31.60014-96.66167-31.91287-98.48119L679.728,595.85974s42.37483,6.7662,46.32659,7.96025S765.16,624.261,765.16,624.261s35.83585-25.82869,2.3169-51.913-91.13226-45.21755-91.13226-45.21755l-20.66857-5.81387-18.01336-1.07517-2.65227-10.17093-42.66206,2.46143-44.52122,18.89181Z"
        transform="translate(-174.5 -76.9729)"
        fill="#2f2e41"
      />
      <circle cx="431.39477" cy="263.7" r="33.23151" fill="#9f616a" />
      <path
        d="M575.8302,367.38291l3.99683-.687s7.21885,27.79487,29.55057,32.38624c0,0,11.01915-13.20039,10.54876-15.4277s8.72665-14.111,13.61662-7.45845-1.19372,123.84077,2.91441,125.94457,12.92142,4.33553,5.82817,12.11113-35.26728,18.2379-41.50765,14.62731-18.99129-34.20115-24.50673-44.49277-14.343-45.30305-14.343-45.30305-11.27257-38.33772-4.33569-47.02309S575.8302,367.38291,575.8302,367.38291Z"
        transform="translate(-174.5 -76.9729)"
        fill="#d0cde1"
      />
      <path
        d="M607.63458,511.65358a189.57234,189.57234,0,0,1-3.80657,24.26808c-1.62981,6.508-3.52744,10.43726-5.693,10.26986-5.52564-.413-8.03729,7.69124-9.33222,17.03456-1.38419,9.91264-1.38419,21.22057-2.14325,25.2169-1.00462,5.28008-7.43445,9.30987-20.28293,8.1266a90.65925,90.65925,0,0,1-22.3481-5.481c-17.34708-6.307-16.83363-57.45529-13.93125-95.40906,1.77486-23.26347,4.44281-41.58172,4.44281-41.58172s-5.26885-15.71735-6.95445-26.48955a35.2107,35.2107,0,0,1-.50235-5.94976c.25673-9.4103,17.43641-34.83936,27.81787-50.67949,10.39264-15.84012,24.5695-4.21953,24.5695-4.21953l-4.934,14.89124s7.64654,11.79917,7.70241,23.029,18.30708,19.33411,24.85968,24.77042C611.21782,432.866,611.1174,478.46637,607.63458,511.65358Z"
        transform="translate(-174.5 -76.9729)"
        fill="#2f2e41"
      />
      <path
        d="M622.06438,372.08082s1.19752,1.41555,6.681.72495c6.5762-.82821,15.61831,1.4471,17.719,7.72578,3.85229,11.51411,14.71265,58.35239,14.71265,58.35239s4.52051,53.54776,6.55328,65.3746,1.02354,22.30328,3.02786,28.51521-19.78726-11.58514-27.81871-9.26808-13.19156-27.70493-12.18233-38.18136,1.8621-21.86262-.92406-32.62334,2.15346-39.24041,1.96865-45.76508S614.09232,383.96685,622.06438,372.08082Z"
        transform="translate(-174.5 -76.9729)"
        fill="#2f2e41"
      />
      <path
        d="M645.578,538.50662s35.18569,19.19574,38.5531,46.51753S637.069,558.56951,637.069,558.56951Z"
        transform="translate(-174.5 -76.9729)"
        fill="#9f616a"
      />
      <path
        d="M665.66354,519.13159s10.78165,54.34487,27.57717,51.4581-9.382-54.58544-9.382-54.58544Z"
        transform="translate(-174.5 -76.9729)"
        fill="#9f616a"
      />
      <path
        d="M723.18359,756.04805s-8.6,9.90785-10.73228,8.40107-7.03635,19.00543-2.61549,22.92875,21.45041,10.36265,21.63522,16.88731,45.85749,5.231,47.49219-1.60642-5.72869-22.4312-10.90294-25.28839-24.3062-29.15464-24.3062-29.15464S728.77005,750.40469,723.18359,756.04805Z"
        transform="translate(-174.5 -76.9729)"
        fill="#2f2e41"
      />
      <path
        d="M639.9734,571.74336c-8.16006-5.76008-30.94346-7.1889-51.17065-8.51728-8.87443-.56932-17.25776-1.12746-23.69876-2.03165-6.20656-.85957-11.788-6.53033-16.74432-14.98063-7.51259-12.80379-13.5852-32.004-18.26245-50.53429,1.77486-23.26347,4.44281-41.58172,4.44281-41.58172s-5.26885-15.71735-6.95445-26.48955a14.17612,14.17612,0,0,1,4.48749-4.041c21.455-9.20938,41.93887,81.422,44.53984,95.64358.04469.23437.07815.45764.11167.64739,1.26134,7.32285,14.89124,13.58527,27.10343,16.0634,7.52376,1.52931,14.52291,1.6298,17.749-.35722,8.47258-5.20187,34.52676,15.61685,36.50255,16.20852C660.05541,552.37573,651.69442,580.03737,639.9734,571.74336Z"
        transform="translate(-174.5 -76.9729)"
        opacity="0.1"
      />
      <path
        d="M532.07668,417.9883s-15.5938,7.36339-8.74209,41.7778,20.6546,92.92317,41.76383,95.85139,63.14288,2.26,74.87024,10.5473,20.08569-19.375,18.10981-19.97208-28.032-21.40766-36.50407-16.205-42.81548-3.88056-44.84825-15.70741S553.88241,408.62058,532.07668,417.9883Z"
        transform="translate(-174.5 -76.9729)"
        fill="#2f2e41"
      />
      <path
        d="M644.44592,401.48442l17.138,39.767s1.72533-.86074,3.72966,5.35119,2.94253,11.67048,6.45363,15.75017,19.13352,62.2757,19.60262,65.005-21.57707,12.13832-26.50318,4.55531S644.44592,401.48442,644.44592,401.48442Z"
        transform="translate(-174.5 -76.9729)"
        fill="#2f2e41"
      />
      <path
        d="M586.98815,340.21033c1.27358-.34005,1.46068-2.031,1.50337-3.34847.22859-7.05539,4.4771-14.295,11.23078-16.34894a18.88129,18.88129,0,0,1,7.95871-.438,30.14537,30.14537,0,0,1,10.77823,3.59581c1.759.98892,3.48436,2.18,5.47953,2.482,1.31767.1995,7.02305,1.85833,8.33513,2.09178,2.88475.51325,5.56568,3.09425,8.33384,2.13381,2.64832-.91886,3.23456-4.34783,3.29857-7.15029.14583-6.38457-4.70095-15.22107-8.97-19.9707-3.23965-3.60433-8.11764-5.26813-12.88608-6.13328a85.79023,85.79023,0,0,0-16.85259-1.02522c-7.63115.1361-15.47383.72511-22.355,4.027s-12.66118,9.87349-12.991,17.49873c-.06871,1.58841.09515,3.18344-.0384,4.76771-.3258,3.86482-2.37806,7.35567-3.34078,11.11282a20.33478,20.33478,0,0,0,2.83974,16.25034c2.5538,3.75828,6.56761,7.15739,6.42,11.69885l5.53742-5.73689a4.54729,4.54729,0,0,0,1.35239-5.55565l-1.96-7.64516a6.89794,6.89794,0,0,1-.16972-4.25467C582.59337,333.06983,584.86469,340.77728,586.98815,340.21033Z"
        transform="translate(-174.5 -76.9729)"
        fill="#2f2e41"
      />
      <path
        d="M838.64648,722.54858c-.22949-.375-5.64062-9.41015-7.5166-28.17187-1.7207-17.21289-.61426-46.22657,14.43262-86.69825,28.50586-76.67089-6.56934-138.5332-6.92773-139.14941l1.73046-1.00391c.09082.15625,9.14161,15.92872,14.48829,41.04395a179.06122,179.06122,0,0,1-7.416,99.80664c-28.457,76.54-7.30078,112.77344-7.084,113.13086Z"
        transform="translate(-174.5 -76.9729)"
        fill="#3f3d56"
      />
      <circle cx="653" cy="374.0542" r="13" fill="#940056" />
      <circle cx="694" cy="422.0542" r="13" fill="#259A04" />
      <circle cx="666" cy="454.0542" r="13" fill="#940056" />
      <circle cx="700" cy="481.0542" r="13" fill="#259A04" />
      <circle cx="656" cy="523.0542" r="13" fill="#3f3d56" />
      <path
        d="M846.5,723.0271s-13-32,26-56Z"
        transform="translate(-174.5 -76.9729)"
        fill="#259A04"
      />
      <path
        d="M830.512,722.44663s-5.91642-34.02934-51.7085-33.73768Z"
        transform="translate(-174.5 -76.9729)"
        fill="#3f3d56"
      />
    </svg>
  );
};
