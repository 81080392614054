import React, { useEffect, useState } from "react";
import { Container, Button, Info } from "./style";
import { BigNav } from "../../globalComponents/Nav";
import { useDispatch, useSelector } from "react-redux";
import { showDrawer } from "../../globalComponents/slices/surveyModalSlice";
import { fetchAchiveSurvey, selectArchive } from "./slice";
import { Row, Col, Pagination, Typography, Button as AntButton } from "antd";
import SurveyCard from "../Dashboard/SurveyCard";
import { useHistory } from "react-router";
import { ModalComponent } from "../../globalComponents/Modal";
import { SideEffect } from "../../Services";
import { notificationAlert } from "../../utils/notificationAlert";

const { Paragraph, Title } = Typography;

const CreateSurvey = () => {
  const dispatcher = useDispatch();
  const { achiveSurveys, paginationProps } = useSelector(selectArchive);
  const history = useHistory();
  const [deleteSurveyProps, setDeleteSurveyProps] = useState({
    loading: false,
    visible: false,
    surveyId: 0,
  });

  useEffect(() => {
    (async () => {
      try {
        const response = await dispatcher(fetchAchiveSurvey({ page: 1 }));
      } catch (error) {}
    })();
  }, []);

  const handleDeleteSurvey = async () => {
    try {
      setDeleteSurveyProps((prevState) => ({ ...prevState, loading: true }));
      const response = await SideEffect.deleteSurvey({
        surveyId: deleteSurveyProps.surveyId,
      });
      await dispatcher(fetchAchiveSurvey({ page: 1 }));
      notificationAlert("success", "Survey Deleted", "Survey has been deleted");
      setDeleteSurveyProps((prevState) => ({
        ...prevState,
        loading: false,
        visible: false,
      }));
    } catch (error) {
      setDeleteSurveyProps((prevState) => ({ ...prevState, loading: false }));
      if (error.response) {
        notificationAlert("error", "Failed", "Please try again later");
      }
    }
  };

  const surveyCardArray = [...achiveSurveys].map((survey, id) => (
    <Col xs={24} sm={12} md={6} key={survey.id}>
      <SurveyCard
        onDelete={() =>
          setDeleteSurveyProps((prevState) => ({
            ...prevState,
            surveyId: survey.id,
            visible: true,
          }))
        }
        onClick={() => {
          localStorage.surveyId = survey.id;
          history.push("/dashboard/create-questionnaire");
        }}
        isArchive
        questionNumber={survey.num_questions}
        questionTitle={survey.survey_name}
        respondents={survey.responses}
        active={survey.string_status === "Active"}
        id={survey.id}
      />
    </Col>
  ));

  const handlePagination = async (page, pageTotal) => {
    console.log({ page, pageTotal });

    try {
      await dispatcher(fetchAchiveSurvey({ page: page }));
    } catch (error) {}
  };

  return (
    <>
      <BigNav title="Survey Archive" />
      {/* <Container> */}
      {Boolean(achiveSurveys.length) ? (
        <Row style={{ marginTop: "20px", paddingLeft: "10px" }} gutter={[6, 6]}>
          {" "}
          {surveyCardArray}{" "}
        </Row>
      ) : (
        <Row
          style={{
            textAlign: "center",
            minHeight: "100vh",
          }}
          justify="center"
          align='middle'
        >
          <Col>
            <Button onClick={() => dispatcher(showDrawer())}>
              Create Survey
            </Button>
            <Info>Your Survey Archive is empty, create a new survey</Info>
          </Col>
        </Row>
      )}

      {/* </Container> */}
      <div style={{ display: "grid", placeItems: "center" }}>
        <Pagination
          onChange={handlePagination}
          defaultCurrent={1}
          total={10 * paginationProps.pages}
        />
      </div>
      <ModalComponent
        visible={deleteSurveyProps.visible}
        onCancel={() =>
          setDeleteSurveyProps((prevState) => ({
            ...prevState,
            visible: false,
          }))
        }
      >
        <Row style={{ padding: 15 }}>
          <Col span={24}>
            <Title style={{ textAlign: "center" }} level={4}>
              Delete Survey
            </Title>
          </Col>
          <Col span={24}>
            <Paragraph>Do you wish to delete survey?</Paragraph>
          </Col>
          <Col offset={15}>
            <AntButton
              onClick={() =>
                setDeleteSurveyProps((prevState) => ({
                  ...prevState,
                  visible: false,
                }))
              }
            >
              Close
            </AntButton>
            <AntButton
              loading={deleteSurveyProps.loading}
              onClick={handleDeleteSurvey}
              style={{ marginLeft: "15px" }}
              type="primary"
              danger
            >
              Delete
            </AntButton>
          </Col>
        </Row>
      </ModalComponent>
    </>
  );
};

export default CreateSurvey;
