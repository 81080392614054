import { useEffect, useState } from "react";

export default function useScreenSize(size) {
  const [isWithinScreenSize, setIsWithinScreenSize] = useState(false);
  useEffect(() => {
    setIsWithinScreenSize(window.innerWidth <= size);

    window.addEventListener("resize", () => {
      setIsWithinScreenSize(window.innerWidth <= size);
    });
    return () =>
      window.removeEventListener("resize", () => {
        setIsWithinScreenSize(window.innerWidth <= size);
      });
  }, [size]);

  return {
    isWithinScreenSize,
  };
}
