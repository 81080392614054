import { createSlice } from "@reduxjs/toolkit";
import { SideEffect } from "../../Services";

const fetchUserInfoSlice = createSlice({
  name: "fetchInfo",
  initialState: {
    data: {},
    surveyList: [],
    activeSurveyId: "",
    purchasedSurveys: {
      surveys: [],
    },
    paginationProps: { pages: 0, total: 0 },
  },
  reducers: {
    userInfo: (state, { payload }) => {
      state.data = payload;
    },
    addSurveyList: (state, { payload }) => {
      state.surveyList = payload;
    },

    addActiveSurveyId: (state, { payload }) => {
      state.activeSurveyId = payload;
    },

    addPurchasedSurvey: (state, { payload }) => {
      state.purchasedSurveys = payload;
    },
    addPaginationProps: (state, { payload }) => {
      state.paginationProps = payload;
    },
  },
});

export const {
  userInfo,
  addSurveyList,
  addActiveSurveyId,
  addPurchasedSurvey,
  addPaginationProps,
} = fetchUserInfoSlice.actions;

export const fetchUserInfo = (payload) => (dispatcher) => {
  return SideEffect.fetchUserInfo()
    .then((response) => {
      dispatcher(userInfo(response.data.data));
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      if (error.response) {
        console.log("fetch user error", error.response);
        return Promise.reject(error.response.data);
      }
    });
};

export const fetchAssignedSurvey = (payload) => (dispatcher) => {
  return SideEffect.fetchAssaignedSurvey(payload)
    .then((response) => {
      dispatcher(addSurveyList(response.data.data));
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      if (error.response) {
        return Promise.reject(error.response.data);
      }
    });
};

export const fetchPurchasedSurvey = (payload) => (dispatcher) => {
  return SideEffect.fetchPurchasedSurvey(payload)
    .then((response) => {
      console.log(response);
      dispatcher(addPurchasedSurvey(response.data.data));
      dispatcher(
        addPaginationProps({
          pages: response.data.data.pages,
          total: response.data.data.total_survey,
        })
      );
      return Promise.resolve(response.data.data);
    })
    .catch((error) => {
      if (error.response) {
        return Promise.reject(error.response.data);
      }
    });
};

export const selectGlobalData = (state) => state.globalData;

export default fetchUserInfoSlice.reducer;
