import React from "react";
import { BigNav } from "../../globalComponents/Nav";
import { Avatar } from "../../globalComponents/Avatar";
import {
  ComponentContainer,
  TextInput,
  Label,
} from "../../globalComponents/Index";
import { useDispatch, useSelector } from "react-redux";
import { Form, Row, Col, Tag, Table, Progress } from "antd";
import styled from "styled-components";
import { MdEmail as MAilIcon } from "react-icons/md";
import { FaShapes } from "react-icons/fa";
import { device } from "../../globalAsset/mediaQueries";
import { useHistory } from "react-router-dom";
import { theme } from "../../globalAsset/theme";
import { IoPersonCircleSharp, IoLocationSharp } from "react-icons/io5";
import { AiFillPhone } from "react-icons/ai";

const Profile = () => {
  const userInfo = useSelector((state) => state.globalData.data);

  const history = useHistory();
  // const userInfo = {
  //   name: "Test Test",
  //   sponsorType: "Business Organisation",
  //   email: "TestOrganisation.com",
  //   address: "Hello world address",
  //   phoneNumber: "081234556",
  //   state: "Borno",
  // };
  const columns = [
    {
      title: "Acc. Name",
      dataIndex: "acc_name",
      key: "acc_name",
    },
    {
      title: "Acc No.",
      dataIndex: "acc_no",
      key: "acc_no",
    },
    {
      title: "Bank Name",
      dataIndex: "bank_name",
      key: "bank_name",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },

    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
  ];
  return (
    <>
      <BigNav title="Profile" />
      <ComponentContainer>
        <Row gutter={[16, 16]}>
          <Col
            // span={}
            xs={24}
            sm={12}
            md={12}
            lg={12}
            xl={12}
           
          >
            <Form layout="vertical" >
              <Avatar />
              <ShowModalDiv
                onClick={() => history.push("/dashboard/update-profile")}
              >
                <Tag color="red">Update Profile</Tag>
                {userInfo?.progress_business !== 100 && (
                  <UpdateInfoDiv
                    onClick={() => history.push("/dashboard/updateprofile")}
                  >
                    <div>
                      <FirstText>
                        Hi,
                        {`${userInfo?.firstname} ${userInfo?.lastname}`}
                      </FirstText>
                      <SecondText>Click here to complete profile</SecondText>
                    </div>
                    <Progress
                      strokeColor="white"
                      type="circle"
                      trailColor="grey"
                      percent={userInfo?.progress_business}
                      width={60}
                    />
                  </UpdateInfoDiv>
                )}
              </ShowModalDiv>
              <Form.Item label={<>Organisation/Researcher Name</>}>
                <TextInput
                  value={`${userInfo && userInfo?.firstname} ${
                    userInfo && userInfo?.lastname
                  }`}
                  icon={<IoPersonCircleSharp />}
                />
              </Form.Item>
              <Form.Item label={<>Sponsor Type</>}>
                <TextInput
                  value={`${
                    userInfo?.sponsor_type
                      ? userInfo?.sponsor_type
                      : "complete profile"
                  }`}
                  icon={<FaShapes />}
                />
              </Form.Item>
              <Form.Item label={"Email"}>
                <TextInput value={userInfo?.email} icon={<MAilIcon />} />
              </Form.Item>
              <Form.Item label="Address">
                <TextInput
                  value={`${
                    userInfo?.address ? userInfo?.address : "complete profile"
                  }`}
                  icon={<IoLocationSharp />}
                />
              </Form.Item>
              <Form.Item label="Phone Number">
                <TextInput
                  value={`${
                    userInfo?.phone_number
                      ? userInfo?.phone_number
                      : "complete profile"
                  }`}
                  icon={<AiFillPhone />}
                />
              </Form.Item>
              <Form.Item label="State">
                <TextInput
                  value={userInfo?.state}
                  //   icon={<PhoneFilled className="site-form-item-icon" />}
                  icon={<IoLocationSharp />}
                />
              </Form.Item>
              {/* <FormItemDisplay label="LGA">
                <TextInputDisplay
                  value={userInfo?.lga}
                  //   icon={<PhoneFilled className="site-form-item-icon" />}
                  icon={<ILocationIcon />}
                />
              </FormItemDisplay> */}
            </Form>
          </Col>
          <Col md={12}>
            <TableLabel>Transaction Summary</TableLabel>
            <Table style={{ fontSize: "12px" }} columns={columns} />
          </Col>
        </Row>
      </ComponentContainer>
    </>
  );
};

export default Profile;
const UpdateInfoDiv = styled.div`
  width: 100%;
  padding: 10px;
  height: 80px;
  background: purple;
  border-radius: 5px;
  display: flex;
  margin: 10px 0;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
`;
const FirstText = styled.h3`
  font-size: 14px;
  text-align: left;
  font-weight: bold;
  color: white;
`;

const SecondText = styled.h4`
  font-size: 10px;
  text-align: left;
  color: white;
`;

const ShowModalDiv = styled.div`
  display: none;
  @media ${device.laptop} {
    display: block;
    cursor: pointer;
    text-align: center;
    margin: 1vw 0;
  }
`;
const LaptopView = styled.div`
  display: none;
  @media ${device.laptop} {
    display: block;
    padding: 0px 10px;
    ${"" /* text-align: left; */}
  }
`;
const MobileView = styled.div`
  display: block;
  @media ${device.laptop} {
    display: none;
  }
`;
const DescriptionItem = styled.div`
  display: flex;
  padding: 0 10px;
  justify-content: space-between;
`;
const DescriptionTitle = styled.h5`
  ${"" /* color: #5a5a5a; */}
  font-size: 1vw;
`;
const DescriptionValue = styled.p`
  ${"" /* color: #000000; */}
  font-size: 13px;
  text-align: center;
`;

const TableLabel = styled.div`
  width: 100%;
  border-radius: 5px;
  background: ${theme.lightgreen};
  color: white;
  text-align: center;
  font-weight: 400;
  padding: 10px 10px;
`;
const FirstText2 = styled.h3`
  font-size: 14px;
  text-align: left;
  font-weight: bold;
  color: white;
`;

const SecondText2 = styled.h4`
  font-size: 10px;
  text-align: left;
  color: white;
`;
