import React from 'react';
import { SingleQuestionScaffold } from '../elements/singleQuestionScaffold';
import { DropDown } from '../atom/SingleQuestion';

export const DropDownQuestion = (props) => {
    return (
        <SingleQuestionScaffold questionId={props.questionObj.questionId} questionNumber={props.questionNumber} question={props.questionObj.question}>
            <DropDown options={props.questionObj.options}/>
        </SingleQuestionScaffold>
    );
};