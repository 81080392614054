import React, { useState, useRef } from "react";
import styled from "styled-components";
import { IoCameraSharp } from "react-icons/io5";
import { getBase64 } from "../helperFunctions/getBase64";
import { updateImage } from "../Components/UpdateProfile/slice";
import { fetchUserInfo } from "../Components/Dashboard/slice";
// import {
//   updateImage,
//   clearNotification,
// } from "../redux-toolkit/updateImageSlice";
// import {
//   MessageNotificationError,
//   MessageNotificationSuccessShort,
// } from "./Index";
import { useSelector, useDispatch } from "react-redux";
import { theme } from "../globalAsset/theme";
import { notificationAlert } from "../utils/notificationAlert";

export const Avatar = () => {
  const userInfo = useSelector((state) => state.globalData.data);
  const updateInfo = useSelector((state) => state.updateReducer.success);
  console.log("update profile", updateInfo);

  const dispatcher = useDispatch();
  //   const userInfoNotification = useSelector(
  //     (state) => state.userInfo.fetchuserInfoNotification
  //   );

  //   const userInfo = useSelector((state) => state.userInfo.userInfo);
  //   const updateNotification = useSelector(
  //     (state) => state.updateImage.notification
  //   );
  //   console.log("userinfo profile pics", userInfo?.profile_pic);

  //   console.log("update notification", updateNotification);
  const [imgUrl, setImgUrl] = useState({});
  const imageRef = useRef();

  React.useEffect(() => {
    dispatcher(fetchUserInfo());
  }, [updateInfo === true]);

  //   React.useEffect(() => {
  //     if (updateNotification === "success") {
  //       // MessageNotificationSuccessShort("Image uploaded successfully");
  //     }
  //     if (updateNotification === "error") {
  //       MessageNotificationError("Image not uploaded please try again");
  //     }
  //     dispatcher(clearNotification());
  //   }, [updateNotification]);
  // React.useEffect(() => {
  //   setImgUrl(`data:image/png;base64,${userInfo?.profile_pic}`);
  // }, []);

  //   React.useEffect(() => {
  //     if (userInfoNotification === "success") {
  //       setImgUrl(`data:image/png;base64,${userInfo?.profile_pic}`);
  //       dispatcher(clearNotification());
  //     }
  //   }, [userInfoNotification]);

  const handleChange = () => {
    // console.log(imageRef.current.files[0]);
    setImgUrl(imageRef.current.files);
    getBase64(imageRef.current.files[0], (data) => {
      // setImgUrl(data);
      const base64 = data.split(",")[1];
      // console.log("the split", base64);
      // setImgUrl(base64);

      try {
        dispatcher(updateImage(base64));
        // console.log("the response is ", response);
        console.log("sucessfully updated");
        // notificationAlert("success", "Updated", "Profile Picture Update");
      } catch (e) {
        console.log("the error", e);
      }
    });
  };

  const handleClick = () => {
    imageRef.current.click();
  };
  return (
    <StyledUpload onClick={handleClick}>
      <StyledInput
        onChange={handleChange}
        style={{ display: "none" }}
        ref={imageRef}
        type="file"
      />
      <StyledCamera />
      {imgUrl === {} ? (
        <h3>+upload</h3>
      ) : (
        <StyledImg src={`data:image/png;base64,${userInfo?.profile_pic}`} />
      )}
    </StyledUpload>
  );
};

const StyledUpload = styled.div`
  margin: 0 auto 0 auto;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid ${theme.grey};
  display: flex;
  position: relative;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 120px;
  ${"" /* border: none; */}
  height: 120px;
  border-radius: 50%;
`;

const StyledImg = styled.img`
  width: inherit;
  height: inherit;
  border: none;
  border-radius: inherit;
`;
const StyledInput = styled.input`
  ${"" /* visibility: none; */}
`;
const StyledCamera = styled(IoCameraSharp)`
  font-size: 26px;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 10px;
  color: white;
  border-radius: 50%;
  padding: 5px;
  background: ${theme.darkorange};
`;
