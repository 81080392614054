import React from "react";
import styled from "styled-components";
import { device } from "../../../../globalAsset/mediaQueries";
import { Select, Input } from "antd";
import {
  FiTrash2,
  FiPlus,
  FiCircle,
  FiChevronDown,
  FiSquare,
  FiPlusSquare,
} from "react-icons/fi";

export const DynamicOption = (props) => {
  return (
    <StyledDynamicOption>
      <StyledOptionName>
        <div>{props.optionIcon}</div>
        {/*  <Input onChange={props.onOptionChange} value={props.optionValue} placeholder="Borderless" bordered={false} /> */}
        <Input.TextArea
          autoSize={true}
          onChange={props.onOptionChange}
          value={props.optionValue}
          placeholder="Borderless"
          bordered={false}
          disabled={props.hideDelete }
        />
      </StyledOptionName>
      {!props.hideDelete && <StyledFiTrash2 onClick={props.onOptionDelete} />}
    </StyledDynamicOption>
  );
};

const StyledDynamicOption = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px solid #a8a8a8;
  margin-bottom: 15px;
`;

const StyledOptionName = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 95%;

  & div:first-child {
    margin-right: 10px;
    color: #a8a8a8;
  }
`;

const StyledFiTrash2 = styled(FiTrash2)`
  font-size: 16px;
  cursor: pointer;

  @media ${device.laptop} {
    font-size: 1vw;
  }
`;

const StyledFiSquare = styled(FiSquare)`
  font-size: 16px;

  @media ${device.laptop} {
    font-size: 1vw;
  }
`;
